import axios from 'axios'
import {BASE_API,BASE_API_STAGING,TOKEN_NAME,BASE_API_PRERELEASE} from './constants/actionTypes'
import 'url-search-params-polyfill'
//import { Cookies } from 'react-cookie'
let token = null ;
let href = window.location.href.includes('/login') ? '' : window.location.href;
let host=window.location.hostname
host= host.split(".")
let hosti=(host!==undefined && host!==null && host[0]!==undefined)?host[0]:'';
let BASE_APIS=(hosti=='staging' || hosti=='localhost' || hosti=='sadmin')?BASE_API_STAGING:BASE_API;
//let BASE_APIS=(hosti=='staging'  || hosti=='sadmin')?BASE_API_STAGING:BASE_API;
if(hosti=='prerelease'){
	BASE_APIS=BASE_API_PRERELEASE;
}

//console.log(hosti)	  
const requests = (url, body) => {

	let api = BASE_APIS + url;

	var params = new URLSearchParams();

	for (let key in body) {
		if (body[key]) {
			params.set(key, body[key]);
		}
	}


	return axios({
		method: 'POST',
		headers: {
			//'Content-Type' : 'multipart/form-data', 
			'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
		},
		url: api,
		data: params,
		validateStatus: (status) => {
			
			//console.log(status);
			if(status==undefined || status=='' || status==NaN || (status>500 && status<=505)){
				alert("The system is experiencing technical difficulties.  Our support team is working to restore services ASAP.");
			}else if(status==500){
				alert("500 Internal Server Error A generic error message, given when no more specific message is suitable");
			}
			return true;
		},
	}).then(response => response.data).catch(e => { 
		//console.log(e);
		if(e!=undefined){
			if (e.response && e.response.status === 401) {
				window.sessionStorage.setItem('previous_href', href);
				window.location.href = '/login'
			}if (e.response && (e.response.status === 404 || e.response.status === '404')) {
				//console.log(e.response, e.response.status)
				alert("error 1:" + e.response.status + " " + e.response.statusText + " : with api" + e.response.request.responseURL)
			}if(e.response && e.response.status >=500 && e.response.status <=505){
				//console.log(e.response , e.response.status)
				alert("The system is experiencing technical difficulties.  Our support team is working to restore services ASAP.");
			
			} else {
				console.log(e.response, e.response.status)
				alert("error 1:" + e.response.status + " " + e.response.statusText + " : with api" + e.response.request.responseURL)

			}
		}else if(e.response==undefined){
			alert("The system is experiencing technical difficulties.  Our support team is working to restore services ASAP.")
		}
	});

};
const requestToken = (url, token) => {
	
    let api = BASE_APIS + url;
      
    if(token && token!==null && token!==''){
		return axios({
		  method: 'POST',
		  headers : {
			'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
			'Authorization': 'Bearer ' + token ,
			'Accept': 'application/json'	
		  },            
		  url: api,
		  data: {},         
		  validateStatus: (status) => {
			if(status==undefined || status=='' || status==NaN || (status>500 && status<=505)){
				alert("The system is experiencing technical difficulties.  Our support team is working to restore services ASAP.");
			}else if(status==500){
				alert("500 Internal Server Error A generic error message, given when no more specific message is suitable");
			}
			return true; 
		  },
		}).then(response => response.data).catch(e => {
			if(e!==undefined){
				if(e.response && e.response.status === 401){
					//setToken(null)
					//window.localStorage.setItem(TOKEN_NAME, '')
					window.sessionStorage.setItem('previous_href', href);
					window.location.href = '/login'
				}else if(e.response && e.response.status >=500 && e.response.status <=505){
					console.log(e.response , e.response.status)
					alert("The system is experiencing technical difficulties.  Our support team is working to restore services ASAP.");
				
				}else{
					console.log(e.response , e.response.status)
					alert("error 2:"+e.response.status+" "+e.response.statusText+" : with api"+e.response.request.responseURL)
				
				}
			}
		});		
	}else{
		window.sessionStorage.setItem('previous_href', href);
		window.location.href = '/login'
	}

};
const requestNew = (url, body) => {
	
    let api = BASE_APIS + url;

    var params = new URLSearchParams();
  
    for(let key in body)
    {
        if(body[key]){
            params.set(key, body[key]);    
        }
    }
  //  console.log(params)      
    //const cookies = new Cookies()
	//const token = cookies.get(TOKEN_NAME)
	const token=window.localStorage.getItem('TOKEN_NAME');
	if(token && token!==null && token!==''){
		return axios({
		  method: 'POST',
		  headers : {
			'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
			'Authorization': 'Bearer ' + token ,
			'Accept': 'application/json'	
		  },            
		  url: api,
		  data: params,         
		  validateStatus: (status) => {
			//console.log(status)
			if(status==undefined || status=='' || status==NaN || (status>500 && status<=505)){
				alert("The system is experiencing technical difficulties.  Our support team is working to restore services ASAP.");
			}else if(status==500){
				alert("500 Internal Server Error A generic error message, given when no more specific message is suitable");
			}
			return true; 
		  },
		}).then(response => response.data).catch(e => {
			if(e!==undefined){
				if(e.response && e.response.status === 401){
					//setToken(null)
					//window.localStorage.setItem(TOKEN_NAME, '')
					if (host != 'localhost') {
						window.sessionStorage.setItem('previous_href', href);
						window.location.href = '/login'
					}
				}else if(e.response && e.response.status >=500 && e.response.status <=505){
					console.log(e.response , e.response.status)
					alert("The system is experiencing technical difficulties.  Our support team is working to restore services ASAP.");
				
				}else{
					console.log(e.response , e.response.status)
					alert("error 3:"+e.response.status+" "+e.response.statusText+" : with api"+e.response.request.responseURL)
				
				}
			}
				
			
		});		
	}else{
		window.sessionStorage.setItem('previous_href', href);
		window.location.href = '/login'
	}

};
const requestPut = (url, body) => {
	
    let api = BASE_APIS + url;

    var params = new URLSearchParams();
  
    for(let key in body)
    {
        if(body[key]){
            params.set(key, body[key]);    
        }
    }
  // console.log(body)      
    //const cookies = new Cookies()
	//const token = cookies.get(TOKEN_NAME);
	const token=window.localStorage.getItem('TOKEN_NAME');
	if(token && token!==null && token!==''){
		return axios({
		  method: 'PUT',
		  headers : {
			'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
			'Authorization': 'Bearer ' + token ,
			'Accept': 'application/json'	
		  },            
		  url: api,
		  data: params,         
		  validateStatus: (status) => {
			if(status==undefined || status=='' || status==NaN || (status>500 && status<=505)){
				alert("The system is experiencing technical difficulties.  Our support team is working to restore services ASAP.");
			}else if(status==500){
				alert("500 Internal Server Error A generic error message, given when no more specific message is suitable");
			}
			return true; 
		  },
		}).then(response => response.data).catch(e => {
			if(e!==undefined){
				if(e.response && e.response.status === 401){
					//setToken(null)
					//window.localStorage.setItem(TOKEN_NAME, '')
					if (host != 'localhost') {
						window.sessionStorage.setItem('previous_href', href);
						window.location.href = '/login'
					}
				}else if(e.response && e.response.status >=500 && e.response.status <=505){
					console.log(e.response , e.response.status)
					alert("The system is experiencing technical difficulties.  Our support team is working to restore services ASAP.");
				
				}else{
					console.log(e.response , e.response.status)
					alert("error 4:"+e.response.status+" "+e.response.statusText+" : with api"+e.response.request.responseURL)
				
				}
			}
		});		
	}else{
		window.sessionStorage.setItem('previous_href', href);
		window.location.href = '/login'
	}

};
const requestDELETE= (url, body) => {
	
    let api = BASE_APIS + url;

    var params = new URLSearchParams();
  
    for(let key in body)
    {
        if(body[key]){
            params.set(key, body[key]);    
        }
    }
  //  console.log(params)      
   // const cookies = new Cookies()
	//const token = cookies.get(TOKEN_NAME)
	const token=window.localStorage.getItem('TOKEN_NAME');
	if(token && token!==null && token!==''){
		return axios({
		  method: 'DELETE',
		  headers : {
			'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
			'Authorization': 'Bearer ' + token ,
			'Accept': 'application/json'	
		  },            
		  url: api,
		  data: params,         
		  validateStatus: (status) => {
			if(status==undefined || status=='' || status==NaN || (status>500 && status<=505)){
				alert("The system is experiencing technical difficulties.  Our support team is working to restore services ASAP.");
			}else if(status==500){
				alert("500 Internal Server Error A generic error message, given when no more specific message is suitable");
			}
			return true; 
		  },
		}).then(response => response.data).catch(e => {
			if(e!==undefined){
				if(e.response && e.response.status === 401){
					//setToken(null)
					//window.localStorage.setItem(TOKEN_NAME, '')
					if (host != 'localhost') {
						window.sessionStorage.setItem('previous_href', href);
						window.location.href = '/login'
					}
				}else if(e.response && e.response.status >=500 && e.response.status <=505){
					console.log(e.response , e.response.status)
					alert("The system is experiencing technical difficulties.  Our support team is working to restore services ASAP.");
				
				}else{
					console.log(e.response , e.response.status)
					alert("error 5:"+e.response.status+" "+e.response.statusText+" : with api"+e.response.request.responseURL)
				
				}
			}
		});				
	}else{
		window.sessionStorage.setItem('previous_href', href);
		window.location.href = '/login'
	}

};
const requestUPDATE= (url, body) => {
	
    let api = BASE_APIS + url;

    var params = new URLSearchParams();
  
    for(let key in body)
    {
        if(body[key]){
            params.set(key, body[key]);    
        }
    }
  //  console.log(params)      
    //const cookies = new Cookies()
	//const token = cookies.get(TOKEN_NAME)
	const token=window.localStorage.getItem('TOKEN_NAME');
	if(token && token!==null && token!==''){
		return axios({
		  method: 'UPDATE',
		  headers : {
			'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
			'Authorization': 'Bearer ' + token ,
			'Accept': 'application/json'	
		  },            
		  url: api,
		  data: params,         
		  validateStatus: (status) => {
			if(status==undefined || status=='' || status==NaN || (status>500 && status<=505)){
				alert("The system is experiencing technical difficulties.  Our support team is working to restore services ASAP.");
			}else if(status==500){
				alert("500 Internal Server Error A generic error message, given when no more specific message is suitable");
			}
			return true; 
		  },
		}).then(response => response.data).catch(e => {
			if(e.response && e.response.status === 401){
				//setToken(null)
				//window.localStorage.setItem(TOKEN_NAME, '')
				if (host != 'localhost'){
					window.sessionStorage.setItem('previous_href', href);
					window.location.href = '/login'
				}
			}else if(e.response && e.response.status >=500 && e.response.status <=505){
					console.log(e.response , e.response.status)
					alert("The system is experiencing technical difficulties.  Our support team is working to restore services ASAP.");
				
			}else{
				console.log(e.response , e.response.status)
				alert("error 6:"+e.response.status+" "+e.response.statusText+" : with api"+e.response.request.responseURL)
			
			}
		});		
	}else{
		window.sessionStorage.setItem('previous_href', href);
		window.location.href = '/login'
	}

};
const requestPayLoad = (url, body) => {
	
    let api = BASE_APIS + url;

    var params = new URLSearchParams();
	
    for(let key in body)
    {
        if(body[key]){
            params.set(key, body[key]);    
        }
    }
    //console.log(body)      
    //const cookies = new Cookies()
	//const token = cookies.get(TOKEN_NAME)
	const token=window.localStorage.getItem('TOKEN_NAME');
	if(token && token!==null && token!==''){
		return axios({
		  method: 'POST',
		  headers : {
			'Content-Type' : 'application/json;charset=UTF-8',
			'Authorization': 'Bearer ' + token ,
			'Accept': 'application/json, text/plain, */*'	
		  },            
		  url: api,
		  data: body,         
		  validateStatus: (status) => {
			if(status==undefined || status=='' || status==NaN || (status>500 && status<=505)){
				alert("The system is experiencing technical difficulties.  Our support team is working to restore services ASAP.");
			}else if(status==500){
				alert("500 Internal Server Error A generic error message, given when no more specific message is suitable");
			}
			return true; 
		  },
		}).then(response => response.data).catch(e => {
			if(e.response && e.response.status === 401){
				//setToken(null)
				//window.localStorage.setItem(TOKEN_NAME, '')
				if (host != 'localhost'){
					window.sessionStorage.setItem('previous_href', href);
					window.location.href = '/login'
				}
			}else if(e.response && e.response.status >=500 && e.response.status <=505){
				console.log(e.response , e.response.status)
				alert("The system is experiencing technical difficulties.  Our support team is working to restore services ASAP.");
			
			}else{
				console.log(e.response , e.response.status)
				alert("error 6:"+e.response.status+" "+e.response.statusText+" : with api"+e.response.request.responseURL)
			
			}
		});		
	}else{
		window.sessionStorage.setItem('previous_href', href);
		window.location.href = '/login'
	}

};
const requestParams = (url, body) => {
	//console.log(body)
    let api = BASE_APIS + url;

    var params = new URLSearchParams();
	
    for(let key in body)
    {
        if(body[key]){
            params.set(key, body[key]);    
        }
    }
    //console.log(body)      
    //const cookies = new Cookies()
	//const token = cookies.get(TOKEN_NAME)
	const token=window.localStorage.getItem('TOKEN_NAME');
	if(token && token!==null && token!==''){
		return axios({
		  method: 'GET',
		  headers : {
			'Content-Type' : 'application/json',
			'Authorization': 'Bearer ' + token ,
			'Accept': 'application/json, text/plain, */*'	
		  },            
		  url: api,        
		  params: body,         
		  validateStatus: (status) => {
			if(status==undefined || status=='' || status==NaN || (status>500 && status<=505)){
				alert("The system is experiencing technical difficulties.  Our support team is working to restore services ASAP.");
			}else if(status==500){
				alert("500 Internal Server Error A generic error message, given when no more specific message is suitable");
			}
			return true; 
		  },
		}).then(response => response.data).catch(e => {
			if(e.response && e.response.status === 401){
				//setToken(null)
				//window.localStorage.setItem(TOKEN_NAME, '')
				if (host != 'localhost'){
					window.sessionStorage.setItem('previous_href', href);
					window.location.href = '/login'
				}
			}else if(e.response && e.response.status >=500 && e.response.status <=505){
				console.log(e.response , e.response.status)
				alert("The system is experiencing technical difficulties.  Our support team is working to restore services ASAP.");
				
			}else{
				console.log(e.response , e.response.status)
				alert("error 7:"+e.response.status+" "+e.response.statusText+" : with api"+e.response.request.responseURL)
			
			}
		});		
	}else{
		window.sessionStorage.setItem('previous_href', href);
		window.location.href = '/login'
	}

};
const requestWithAccept = (url, body) => {
		let api = BASE_APIS + url;
		
		var params = new URLSearchParams();

		for(let key in body)
		{
			if(body[key]){
				params.set(key, body[key]);    
			}
		}
		//const cookies = new Cookies()
		//const token = cookies.get(TOKEN_NAME)
		const token=window.localStorage.getItem('TOKEN_NAME');
		if(token && token!==null && token!==''){
			return axios({
			  method: 'POST',
			  headers : {
				'Authorization': 'Bearer ' + token,
				'Accept': 'application/json'
			  },          
			  url: api,
			  data: params,         
			  validateStatus: (status) => {
				if(status==undefined || status=='' || status==NaN || (status>500 && status<=505)){
					alert("The system is experiencing technical difficulties.  Our support team is working to restore services ASAP.");
				}else if(status==500){
					alert("500 Internal Server Error A generic error message, given when no more specific message is suitable");
				}
				return true; 
			  },
			}).then(response => response.data).catch(e => {
				if(e.response && e.response.status === 401){
					//setToken(null)
					//window.localStorage.setItem(TOKEN_NAME, '')
					window.location.href = '/login'						
				}else if(e.response && e.response.status >=500 && e.response.status <=505){
					console.log(e.response , e.response.status)
					alert("The system is experiencing technical difficulties.  Our support team is working to restore services ASAP.");
				
				}else{
					console.log(e.response , e.response.status)
					alert("error 8:"+e.response.status+" "+e.response.statusText+" : with api"+e.response.request.responseURL)
			
				}
		});
		}else{
			window.location = '/login'	
		}
		
};
const requestGet = (url, body) => {
	
    let api = BASE_APIS + url;

    var params = new URLSearchParams();
  
    for(let key in body)
    {
        if(body[key]){
            params.set(key, body[key]);    
        }
    }
  //  console.log(params)      
    //const cookies = new Cookies()
	//const token = cookies.get(TOKEN_NAME)
	const token=window.localStorage.getItem('TOKEN_NAME');
	//console.log(token)
	if(token && token!==null && token!==''){
		return axios({
		  method: 'GET',
		  headers : {
			'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
			'Authorization': 'Bearer ' + token ,
			'Accept': 'application/json'	
		  },            
		  url: api,
		  data: params,         
		  validateStatus: (status) => {
			if(status==undefined || status=='' || status==NaN || (status>500 && status<=505)){
				alert("The system is experiencing technical difficulties.  Our support team is working to restore services ASAP.");
			}else if(status==500){
				alert("500 Internal Server Error A generic error message, given when no more specific message is suitable");
			}
			return true; 
		  },
		}).then(response => response.data).catch(e => {
			
			if(e.response && e.response.status === 401){
				//setToken(null)
				//window.localStorage.setItem(TOKEN_NAME, '')
				if (host != 'localhost'){
					window.sessionStorage.setItem('previous_href', href);
					window.location.href = '/login'
				}
			}else if(e.response && e.response.status >=500 && e.response.status <=505){
				console.log(e.response , e.response.status)
				alert("The system is experiencing technical difficulties.  Our support team is working to restore services ASAP.");
			
			}else if(e.response!==undefined){
				console.log(e.response , e.response.status)
				alert("error 9:"+e.response.status+" "+e.response.statusText+" : with api"+e.response.request.responseURL)
			
			}
		});		
	}else{
		window.sessionStorage.setItem('previous_href', href);
		window.location.href = '/login'
	}

};
const requestforArray = (url, params) => {
	//checktoken();
	let api = BASE_APIS + url;
    
    const formData  = new FormData()
	  for(let name in params) {
		let param = params[name]
		if (typeof param === 'object') {
		  param = JSON.stringify(params[name])
		}
		formData.append(name, param)
	  }
	//console.log(params)
	//const cookies = new Cookies()
	//const token = cookies.get(TOKEN_NAME)
	const token=window.localStorage.getItem('TOKEN_NAME');
			
		return axios({
		  method: 'POST',
		  headers : {
			'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
			'Authorization': 'Bearer ' + token ,
			'Accept': 'application/json'	
		  },          
		  url: api,
		  data: formData,         
		  validateStatus: (status) => {
			if(status==undefined || status=='' || status==NaN || (status>500 && status<=505)){
				alert("The system is experiencing technical difficulties.  Our support team is working to restore services ASAP.");
			}else if(status==500){
				alert("500 Internal Server Error A generic error message, given when no more specific message is suitable");
			}
			return true; 
		  },
		}).then(response => response.data).catch(e => {
			//console.log(e.response , e.response.status)
			alert("error 10:"+e.response.status+" "+e.response.statusText+" : with api"+e.response.request.responseURL)
			if(e.response && e.response.status === 401){
				//setToken(null)
				//window.localStorage.setItem(TOKEN_NAME, '')
				window.location.href = '/login'					
			}else if(e.response && e.response.status >=500 && e.response.status <=505){
				console.log(e.response , e.response.status)
				alert("The system is experiencing technical difficulties.  Our support team is working to restore services ASAP.");
			
			}
		});
	
};
const submitform = (url, body) => {
   //checktoken();
  let BASE_APIS="https://back-end.coopbet.com/api/"
   let api = BASE_APIS + url;
   let fromData = new FormData();
   //var fromData = new URLSearchParams();
   //let fromData = {}
		for(let key in body){
			if( body[key] != null && body[key].isArray ){  
				for(let i in body[key]){
					fromData.append(key + '[]', body[key][i]);                       
				}        
			} else {
				fromData.append(key, body[key]);    
			}  
		} 
   let userinfo = window.localStorage.getItem(TOKEN_NAME)	
   //if(userinfo!=''){
	   //const data = JSON.parse(userinfo)
		//console.log(data);
		let access_token = ''
		let expires_in = ''
		let refresh_token = ''
		let token_type = ''
		/*if(data != '' && typeof data !== 'undefined' && data != null ){
			 access_token = data.access_token
			 expires_in = data.expires_in
			 refresh_token = data.refresh_token
			 token_type = data.token_type
		}
		*/
		return axios({
		  method: 'POST',
		  headers : {
			'Content-Type' : 'multipart/form-data', 
			'Authorization': 'Bearer ' + access_token      
		  },          
		  url: api,
		  data: fromData,
		  validateStatus: (status) => {
			if(status==undefined || status=='' || status==NaN || (status>500 && status<=505)){
				alert("The system is experiencing technical difficulties.  Our support team is working to restore services ASAP.");
			}else if(status==500){
				alert("500 Internal Server Error A generic error message, given when no more specific message is suitable");
			}
			return true; 
		  },

		}).then(response => response.data).catch(e => {
			if(e.response && e.response.status === 401){
				//setToken(null)
				//window.localStorage.setItem(TOKEN_NAME, '')
				window.location.href = 'login'					
			}else if(e.response && e.response.status >=500 && e.response.status <=505){
				console.log(e.response , e.response.status)
				alert("The system is experiencing technical difficulties.  Our support team is working to restore services ASAP.");
			
			}
		})
  // }else{
	  // window.location.href = 'login'
 //  }
	  

};
const uploadImg = (url, body) => {
   let api = BASE_APIS + url;
   let fromData = new FormData();
   //console.log(body)
	for(let key in body){
		if( body[key] != null && body[key].isArray ){  
			for(let i in body[key]){
				fromData.append(key + '[]', body[key][i]);                       
			}        
		} else {
			fromData.append(key, body[key]);    
		}  
	} 
   let userinfo = window.localStorage.getItem(TOKEN_NAME)
   if(userinfo!=''){
	   const data = JSON.parse(userinfo)
		//console.log(data);
		let access_token = ''
		let expires_in = ''
		let refresh_token = ''
		let token_type = ''
		if(data != '' && typeof data !== 'undefined' && data != null ){
			 access_token = data.access_token
			 expires_in = data.expires_in
			 refresh_token = data.refresh_token
			 token_type = data.token_type
		}
		
		return axios({
		  method: 'POST',
		  headers : {
			'Content-Type' : 'multipart/form-data', 
			'Authorization': 'Bearer ' + token   
		  },          
		  url: api,
		  data: fromData,         
		  validateStatus: (status) => {
			return true; 
		  },
		}).then(response => response.data).catch(e => {
			if(e!==undefined){
				console.log(e.response , e.response.status)
				alert("error 11:"+e.response.status+" "+e.response.statusText+" : with api"+e.response.request.responseURL)
				if(e.response && e.response.status === 401){
					//setToken(null)
					//window.localStorage.setItem(TOKEN_NAME, '')
					window.location.href = 'login'					
				}
			}
		});		
  }else{
	   window.location.href = 'login'
  }
	  

};
const submitformNew = (url, body) => {
	
	let api = BASE_APIS + url;
   let fromData = new FormData();
   //var fromData = new URLSearchParams();
   //let fromData = {}
		for(let key in body){
			if( body[key] != null && body[key].isArray ){  
				for(let i in body[key]){
					fromData.append(key + '[]', body[key][i]);                       
				}        
			} else {
				fromData.append(key, body[key]);    
			}  
		} 
   
	let userinfo = window.localStorage.getItem(TOKEN_NAME)	
	if(userinfo!=''){
	   const data = JSON.parse(userinfo)
		//console.log(data);
		let access_token = ''
		let expires_in = ''
		let refresh_token = ''
		let token_type = ''
		if(data != '' && typeof data !== 'undefined' && data != null ){
			 access_token = data.access_token
			 expires_in = data.expires_in
			 refresh_token = data.refresh_token
			 token_type = data.token_type
		}
		//const cookies = new Cookies()
		//const token = cookies.get(TOKEN_NAME)
		const token=window.localStorage.getItem('TOKEN_NAME');
		return axios({
		  method: 'POST',
		  headers : {
			'Content-Type' : 'multipart/form-data', 
			'Authorization': 'Bearer ' + token      
		  },          
		  url: api,
		  data: fromData,
		  validateStatus: (status) => {
			if(status==undefined || status=='' || status==NaN || (status>500 && status<=505)){
				alert("The system is experiencing technical difficulties.  Our support team is working to restore services ASAP.");
			}else if(status==500){
				alert("500 Internal Server Error A generic error message, given when no more specific message is suitable");
			}
			return true; 
		  },

		}).then(response => response.data).catch(e => {
			if(e.response && e.response.status === 401){
				//setToken(null)
				//window.localStorage.setItem(TOKEN_NAME, '')
				if (host != 'localhost'){
					window.location.href = '/login'
				}
			}else if(e.response && e.response.status >=500 && e.response.status <=505){
				console.log(e.response , e.response.status)
				alert("The system is experiencing technical difficulties.  Our support team is working to restore services ASAP.");
			
			}
		})
	}
};
const submitformNewPostForm = (url, body) => {
	
	let api = BASE_APIS + url;
  
   
	let userinfo = window.localStorage.getItem(TOKEN_NAME)	
	if(userinfo!=''){
	   const data = JSON.parse(userinfo)
		//console.log(data);
		let access_token = ''
		let expires_in = ''
		let refresh_token = ''
		let token_type = ''
		if(data != '' && typeof data !== 'undefined' && data != null ){
			 access_token = data.access_token
			 expires_in = data.expires_in
			 refresh_token = data.refresh_token
			 token_type = data.token_type
		}
		//const cookies = new Cookies()
		//const token = cookies.get(TOKEN_NAME)
		const token=window.localStorage.getItem('TOKEN_NAME');
		return axios({
		  method: 'POST',
		  headers : {
			'Content-Type' : 'multipart/form-data', 
			'Authorization': 'Bearer ' + token      
		  },          
		  url: api,
		  data: body, 
		  validateStatus: (status) => {
			if(status==undefined || status=='' || status==NaN || (status>500 && status<=505)){
				alert("The system is experiencing technical difficulties.  Our support team is working to restore services ASAP.");
			}else if(status==500){
				alert("500 Internal Server Error A generic error message, given when no more specific message is suitable");
			}
			
			return true; 
		  },

		}).then(response => response.data).catch(e => {
			if(e.response && e.response.status === 401){
				//setToken(null)
				//window.localStorage.setItem(TOKEN_NAME, '')
				if (host != 'localhost'){
					window.location.href = '/login'
				}
			}else if(e.response && e.response.status >=500 && e.response.status <=505){
				console.log(e.response , e.response.status)
				alert("The system is experiencing technical difficulties.  Our support team is working to restore services ASAP.");
			
			}
		})
	}
};
const home = {
	getProgress: params => 
		requestGet('student/progress_dashboard', params),
	getMyclassrooms: (limit,ofset) => 
		requestGet('student/myclassrooms_dashboard/'+ofset+'/'+limit),
	getMyclassroomsAll: params => 
		requestGet('student/myclassrooms_dashboard/0/10', params),
	getInboxMessege: (limit,ofset) => 
		requestGet('message/messages_dashboardv2/all/inbox/'+ofset+'/'+limit),	
	getInClassSections: params => 
		requestNew('student/inclass_sections',params),	
	getUpcomingEvents: params => 
		requestNew('event/upcoming_events',params),	
	getEnrollments: params => 
		requestPayLoad('enrollment/current_enrollments',params),	
	getEventWeeks: params => 
		requestNew('event/event_weeks',params),	
	getEnrollDetail: (enrol_id) => 
		requestGet('enrollment/detail/'+enrol_id),	
	getSectionResource: (params) => 
		requestNew('resource/section_resources',params),	
	getEnrollProgress: params => 
		requestNew('enrollment/progress',params),
	getEnrollEvents: params => 
		requestNew('enrollment/get_enrollment_events',params),
	getStudentAttendance: params => 
		requestNew('section/get_student_attendance',params),
	getStudentOnlineSchedule: params => 
		requestNew('student/online_schedule',params),
	getTeachers: params => 
		requestGet('student/get_teachers',params),
	getProgressTracking: params => 
		requestNew('student/get_progress_tracking',params),
	getGpa: params => 
		requestNew('student/gpa',params),
	get_gpa_progress: params => 
		requestGet('student/get_gpa_progress',params),
	getResource: params => 
		requestNew('resource',params),
	getStudentInfo: params => 
		requestNew('student/informations',params),
	getMessagesInbox: params => 
		requestParams('message/messages_dashboardv2/all/inbox/1/all',params),
	getMessagesSent: params => 
		requestParams('message/messages_dashboardv2/all/sent/1/all',params),
	getMessagesTrash: params => 
		requestParams('message/messages_dashboardv2/all/trash/1/all',params),
	getMessagesTags: (params,id) => 
		requestParams('message/messages_dashboardv2/all/'+id+'/1/all',params),
	getPersonalFolders: params => 
		requestGet('message/gen_personal_folders',params),
	createFolder: params => 
		requestNew('message/create_folder',params),
	deleteFolder: params => 
		requestDELETE('message/delete_folder',params),
	deleteMessages: params => 
		requestDELETE('message/delete_message',params),
	markAsRead: params => 
		requestNew('message/mark_as_read',params),
	messageMoveTo: params => 
		requestNew('message/move_to',params),
	getMessageInfo: (params,id) => 
		requestGet('message/message/'+id,params),
	getEnrollAllProgress: params => 
		requestNew('enrollment/all_progress',params),
	getResourceType: params => 
		requestGet('resource/resource_type',params),
	current_academic_track: params => 
		requestGet('student/current_academic_track',params),
	getTranscript: params => 
		requestNew('student/transcript',params),
	getTranscriptND: params => 
		requestNew('student/transcript_nd',params),	
	get_mandatory_activities: (params) => 
		requestGet('mandatoryactivity/get_mandatory_activities',params),		
	getCoursesSelect: (params) => 
		requestNew('course/courses_select',params),		
	getCoursesStudentILP: (params) => 
		requestNew('course/courses_student_ilp',params),		
	save_compliance_log: (params) => 
		requestNew('mandatoryactivity/save_compliance_log',params),	
	get_feature_settings: (params) => 
		requestGet('school/feature_settings',params),	
	check_configs: (params) => 
		requestGet('personalrule/check_configs',params),	
	get_request_dashboard: (params) => 
		requestGet('request/get_request_dashboard',params),	
	get_comments: (params) => 
		requestNew('comment/get_comments',params),	
	download_learning_path_pdf: (params) => 
		requestNew('student/download_learning_path_pdf',params),	
	client_time_zone: (params) => 
		requestGet('user/client_time_zone',params),
	save_timezone_autodetect: (params) => 
		requestPut('user/save_timezone_autodetect',params),
	get_languages: (params) => 
		requestGet('common/get_languages',params),
	check_connection_speed: (params) => 
		requestGet('check_connection_speed',params),
	save_language: (params) => 
		requestNew('user/save_language',params),
	remove_photo: (params) => 
		requestNew('user/remove_photo',params),	
	
		
};
const messge={
	get_attached_files: (params,id) => 
		requestGet('message/get_attached_files/'+id,params),		
	genSelectTo2: (params,id) => 
		requestGet('message/gen_select_to2/'+id,params),	
	genSelectCc: (params) => 
		requestGet('message/gen_select_cc',params),
	genSelectSections: (params) => 
		requestGet('message/gen_select_sections',params),
	sendmessage: (params) => 
		requestPayLoad('message/sendmessage',params),
	send_global_message: (params) => 
		requestPayLoad('message/send_global_message',params),		
	uploadFilesMessage: (params) => 
		submitformNew('message/upload_files/message',params),
	update_like_status: (params) => 
		requestNew('message/update_like_status',params),
	get_message_start: (page,params) => 
		requestParams('message/messages_dashboardv2/all/starred/'+page+'/all',params),
	get_communication_user_rules: (params) => 
		requestGet('common/get_communication_user_rules',params),	
	gen_message_type: (params) => 
		requestGet('message/gen_message_type',params),	
	gen_mesasge_objt: (params) => 
		requestGet('message/gen_message_objto',params),	
	trash_message: params => 
		requestDELETE('message/trash_message',params),
	get_students_for_parent: (params) => 
		requestGet('message/get_students_for_parent',params),
	get_sections_for_student: (student_id,params) => 
		requestGet('message/get_sections_for_student/'+student_id,params),	
	uploadFilesApplication: (params) => 
		submitformNew('message/upload_files/application',params),
	get_parents_for_obj_in_message: params =>
		requestNew('message/get_parents_for_obj', params),
	get_teachers_for_obj_in_message: params =>
		requestNew('message/get_teachers_for_obj', params),
	get_academic_advisors_for_obj_in_message: params =>
		requestNew('message/get_academic_advisors_for_obj', params),
	save_signature_block: params =>
		requestNew('message/save_signature_block', params),	
	get_signature_block: params =>
		requestNew('message/get_signature_block', params),					
};
const chat={
	get_dis_users: (params) => 
		requestGet('discussion/get_dis_users',params),
	get_dis_teachers: (params) => 
		requestGet('discussion/get_dis_teachers',params),
	get_dis_messages: (id,type,params) => 
		requestGet('discussion/get_dis_messages/'+id+'/'+type,params),
	get_sections_users: (params) => 
		//requestGet('discussion/get_sections_users',params),
		requestParams('discussion/get_sections_users',params),
	get_dis_users_in_section: (sectionid,params) => 
		requestGet('discussion/get_dis_users_in_section/'+sectionid, params),
	get_dis_count_unread: (id,type,params) => 
		requestGet('discussion/get_dis_count_unread/'+id+'/'+type, params),
	get_discussion_summary: (params) => 
		requestGet('user/discussion_summary', params),
	communicationTouser: params => {
		return requestWithAccept('communication-touser', params)
	},
	
	get_dis_recent_messages: (params) => 
		requestGet('discussion/get_dis_recent_messages', params),
	get_dis_messages_latest: (params) => 
		requestNew('discussion/get_dis_messages_latest', params),
	get_communication: (params) => 
		requestNew('discussion/communication', params),
	get_new_messages_chat: (section_id,params) => 
		requestGet('discussion/get_new_messages_chat/'+section_id, params),
	update_announcement_date: params =>
		requestNew('message/update_announcement_date', params),

	save_discussion_thread: (params) =>
		submitformNewPostForm('discussion/save_discussion_thread', params),
	interact_discussion_thread: (params) =>
		requestNew('discussion/interact_discussion_thread', params),
	get_discussion_threads: params =>
		requestNew('discussion/get_discussion_threads', params),
	get_discussion_threads_summary: params =>
		requestNew('discussion/get_discussion_threads_summary', params),
	get_discussion_thread_detail: params =>
		requestNew('discussion/get_discussion_thread_detail', params),
	get_list_user_participant: params =>
		requestNew('discussion/get_list_user_participant', params),
	save_discussion_reply: (params) =>
		submitformNewPostForm('discussion/save_discussion_reply', params),
	interact_discussion_reply: (params) =>
		requestNew('discussion/interact_discussion_reply', params),
	get_discussion_replies: params =>
		requestNew('discussion/get_discussion_replies', params),
	get_discussion_reply_detail: params =>
		requestNew('discussion/get_discussion_reply_detail', params),
	get_role_items: params =>
		requestNew('discussion/get_role_items', params),
	change_discussion_reply_status: params =>
		requestNew('discussion/change_discussion_reply_status', params),
	delete_discussion_thread: params =>
		requestDELETE('discussion/delete_discussion_thread',params),
	delete_discussion_reply: params =>
		requestDELETE('discussion/delete_discussion_reply',params),
}

const auth = {
	doLogin: params =>
		requests('login', params),
	auto_login: token =>
		requestToken('user/auto_login', token),
	logout: params =>
		requestNew('logout', params),
	getUserSchools: (params) => 
		requestGet('school/get_schools_by_user',params),
	getUserSchoolsAll: (params) => 	
		requestGet('school/get_all',params),	
	getSchoolGroupSchools: (params) => 
		requestNew('school/get_schoolgroup_schools',params),	
	changeSchool: (params) => 
		requestPut('user/change_school',params),
	getSchool: params =>
		requests('school', params),
	get_school_byid: (id,params) => 
		requestGet('school/schoolid/'+id,params),	
	change_password: params =>
		requestPut('user/change_password', params),
	forgot_password: params =>
		requests('forgot_password', params),
	reset_password: params =>
		requests('reset_password', params),
	save_timezone: params =>
		requestPut('user/save_timezone', params),
	setDevice: (params) => 
		requestNew('user/device', params),
	personalrule: (params) => 
		requestGet('personalrule', params),
	get_personalrule_list: (params) => 
		requestNew('personalrule/get_personalrule_list', params),
	getLevelurgency: (params) => 
		requestGet('levelurgency', params),
	savePersonalrule: (params) => 
		requestNew('personalrule', params),
	deletePersonalrule: (params) => 
		requestDELETE('personalrule', params),
	save_support_ticket: (params) => 
		submitformNewPostForm('user/save_support_ticket', params),
	user_change_role: (params) => 
		requestPut('user/change_role', params),
	get_user_role: (params) => 
		requestGet('user/roles', params),
		
	save_phone: (params) =>
		requestNew('user/save_phone', params),
	save_address: (params) =>
		requestNew('user/save_address', params),
	save_photo: (params) =>
		submitformNew('user/save_photo', params),
	save_info: (params) =>
		requestNew('user/save_info', params),
	del_address: params => 
		requestDELETE('user/del_address',params),
	del_phone: params => 
		requestDELETE('user/del_phone',params),
	get_statuses: (params) =>
		requestNew('user/get_statuses', params),
}
const parents={
	getEnrollmentsPr: (params) => 
		requestPayLoad('enrollment/student_enrollment',params),
	get_online_section_calendar_pr: params => 
		requestNew('enrollment/online_section_calendar_pr',params),
	get_inclass_sections: params => 
		requestNew('parent/inclass_sections',params),
	get_event_weeks: params => 
		requestNew('parent/event_weeks',params),
	get_progress_tracking: params => 
		requestNew('parent/get_progress_tracking',params),
	get_upcoming_events: params => 
		requestNew('parent/upcoming_events',params),
	getGpa: params => 
		requestNew('parent/gpa',params),
	get_current_academic_track: (id,params) => 
		requestGet('parent/current_academic_track/'+id,params),
	get_current_enrollments: (id,params) => 
		requestPayLoad('enrollment/current_enrollments/'+id,params),
	get_detail: (student_id,enrol_id,params) => 
		requestGet('enrollment/detail/'+student_id+'/'+enrol_id,params),
	get_all_progress: (student_id,enrol_id,params) => 
		requestNew('enrollment/all_progress/'+student_id+'/'+enrol_id,params),
	get_enrollment_events: params => 
		requestNew('parent/get_enrollment_events',params),
	get_gpa_progress: params => 
		requestNew('parent/get_gpa_progress',params),
	get_parent_informations: params => 
		requestGet('user/informations',params),
	get_children: params => 
		requestGet('parent/get_children',params),
	save_photo_request: (params) => 
		submitformNew('student/save_photo_request', params),
	get_inclass_sections_schedule: (params) => 
		requestNew('parent/inclass_sections_schedule',params),
	get_comments_satus: (params) => 
		requestNew('comment/get_comments_status',params),
	student_enrollment_list: (params) => 
		requestNew('parent/student_enrollment_list',params),
	get_students: (params) => 
		requestNew('parent/get_students',params),
	
		
}
const student={
	getCountri: params => 
		requestGet('common/countries',params),
	get_phone_codes: params => 
		requestGet('common/phone_codes',params),
	save_address_request: params => 
		requestNew('student/save_address_request',params),
	save_info_request: params => 
		requestNew('student/save_info_request',params),
	save_phone_request: params => 
		requestNew('student/save_phone_request',params),
	del_address_request: params => 
		requestDELETE('student/del_address_request',params),
	del_phone: params => 
		requestDELETE('student/del_phone',params),
	del_phone_request: params => 
		requestDELETE('student/del_phone_request',params),
	upload_student_documents: params => 
		submitformNewPostForm('comment',params),
	get_comment_type: params => 
		requestNew('comment/get_comment_type',params),
	handling_transcript: params => 
		requestGet('school/handling_transcript',params),
	upload_files: params => 
		submitformNewPostForm('student/upload_files',params),
	save_enroll_request: (params) => 
		requestNew('student/save_enroll_request',params),	
	get_inclass_sections_schedule: (params) => 
		requestNew('student/inclass_sections_schedule',params),	
	get_list_sections: (params) => 
		requestNew('section/list_sections',params),	
	get_progress_detail: (params) => 
		requestNew('enrollment/progress_detail',params),	
	activity_upload_files: (params) => 
		submitformNewPostForm('activitytype/upload_files',params),	
	get_current_enrollments: (params) => 
		requestNew('student/current_enrollments',params),	
	get_academic_track: (params) => 
		requestGet('student/get_academic_track',params),	
	grade_level: (params) => 
		requestGet('student/grade_level',params),
	get_menu_enrollments: (params) => 
		requestNew('student/menu_enrollments',params),	
	update_last_access_time: (params) => 
		requestNew('enrollment/update_last_access_time',params),	
}
const teacher={
	get_home_progress: (id) => 
		requestGet('teacher/home_progress/'+id,{}),
	get_sections: params => 
		requestNew('teacher/get_sections',params),
	get_upcoming_events: params => 
		requestNew('teacher/upcoming_events',params),
	get_section_detail: (section_id,params) => 
		requestNew('section/detail/'+section_id,params),
	get_roster: (params) => 
		requestNew('section/get_roster',params),
	get_list_sections: (params) => 
		requestNew('teacher/list_sections',params),
	get_teacher_activities: (params) => 
		requestNew('teacher/get_teacher_activities',params),
	get_activity_type: (params) => 
		requestGet('section/get_activity_type',params),
	get_students: (params) => 
		requestNew('teacher/get_students',params),
	get_departments: (params) => 
		requestGet('teacher/get_departments',params),
	get_gpa: (params) => 
		requestNew('teacher/gpa',params),
	get_progress_tracking: (params) => 
		requestNew('teacher/get_progress_tracking',params),
	current_academic_track: (id,params) => 
		requestGet('teacher/current_academic_track/'+id,params),
	get_gpa_progress: (params) => 
		requestNew('teacher/get_gpa_progress',params),
	get_all_student_enrollments: (params) => 
		requestNew('enrollment/all_student_enrollments',params),	
	teacher_sections_simple: (school_id) =>
		requestGet('section/teacher_sections_simple/'+school_id,{}),
	teacher_sections_simpleForAdd: (params) =>
		requestNew('section/teacher_sections_simple',params),
		
	get_student_attendance_list: (params) =>
		requestNew('section/get_student_attendance_list', params),
	save_attendance_status: (params) =>
		requestNew('section/save_attendance_status', params),
	get_enrollment_events: (params) =>
		requestNew('teacher/get_enrollment_events', params),
	get_event_weeks: (params) =>
		requestNew('teacher/event_weeks', params),
	get_inclass_sections: (params) =>
		requestNew('teacher/inclass_sections', params),
	get_online_sections: (params) =>
		requestNew('teacher/get_online_sections', params),
	get_event_attendances: (params) =>
		requestNew('teacher/get_event_attendances', params),
	get_inclass_sections_schedule: (params) => 
		requestNew('teacher/inclass_sections_schedule',params),
	get_gradebook: (params) => 
		requestNew('section/get-gradebook',params),
	get_roles: (params) =>
		requestNew('common/get_roles', params),
	get_role_items: (params) =>
		requestNew('common/get_role_items', params),
	save_resource: (params) =>
		submitformNewPostForm('resource/save', params),
	get_all_gradingperiod: (params) =>
		requestNew('gradingperiod/get_all', params),
	save_section_unit: (params) =>
		requestNew('section/save_section_unit', params),		
	get_section_unit: (params) =>
		requestNew('section/get_section_unit', params),
	save_activity: (params) =>
		submitformNewPostForm('section/save_activity', params),
	get_current_enrollment: (params) =>
		requestNew('teacher/current_enrollments', params),
	get_inclass_sections_of_student: (params) =>
		requestNew('teacher/inclass_sections_of_student', params),
	get_inclass_sections_schedule_of_student: (params) =>
		requestNew('teacher/inclass_sections_schedule_of_student', params),
	get_othergrade_of_student: (params) =>
		requestNew('othergrade/get_list', params),
	get_section_unit: (params) =>
		requestNew('section/get_section_unit', params),
	get_activity_detail: (params) =>
		requestNew('section/get_activity_detail', params),
	save_activity: (params) =>
		submitformNewPostForm('section/save_activity', params),
	get_flags: (params) =>
		requestNew('comment/get_flags', params),
	get_comment: (params) =>
		requestNew('comment/detail', params),
	delete_comment: params =>
		requestDELETE('comment', params),
	get_schools_resource: (params) =>
		requestNew('resource/get_schools', params),
	get_resource_detail: (params) =>
		requestNew('resource/detail', params),
	delete_resource: params =>
		requestDELETE('resource', params),
	save_grade: params =>
		submitformNewPostForm('section/save_grade', params),
	set_lock_activity: params =>
		requestNew('section/set_lock_activity', params),
	set_exempted: params =>
		requestNew('section/set_exempted', params),
	set_exempt_all: params =>
		requestNew('section/set_exempt_all', params),
	assign_default_grade: params =>
		requestNew('section/assign_default_grade', params),
	get_submissions_grade: params =>
		requestNew('section/get_submissions_grade', params),
	get_all_activities: params =>
		requestGet('section/get_all_activities', params),
	finalize_section: params =>
		requestNew('section/finalize_section', params),
	save_event: (params) =>
		submitformNewPostForm('event/save', params),

	get_options: params =>
		requestGet('communication/get_options', params),
	get_made_from: params =>
		requestNew('communication/get_made_from', params),
	save_communication: params =>
		submitformNewPostForm('communication', params),
	get_communications: params =>
		requestNew('communication/get_communications', params),
	get_communication_detail: params =>
		requestNew('communication/detail', params),
	delete_communication: params =>
		requestDELETE('communication', params),
	get_section_units: params =>
		requestNew('teacher/get_section_units', params),
	get_all_teacher_sections_simple: (school_id) =>
		requestGet('section/all_teacher_sections_simple/'+school_id,{}),
	unfinalize_section: params =>
		requestNew('section/unfinalize_section', params),
	get_compliance_log: params =>
		requestNew('mandatoryactivity/get_compliance_log', params),
	export_compliance_log: params =>
		requestNew('mandatoryactivity/export_compliance_log', params),
	get_zoom_config: params =>
		requestGet('teacher/get_zoom_config', params),
	save_zoom_config: params =>
		requestNew('teacher/save_zoom_config', params),
	get_user_setting: params =>
		requestGet('user/user_setting', params),
	save_user_setting: params =>
		requestNew('user/save_user_setting', params),
	get_section_facilities: params =>
		requestGet('section/get_section_facilities', params),
	get_enrollment_status: params =>
		requestGet('common/get_enrollment_status', params),
	get_enrollment_status_v2: params =>
		requestNew('common/get_enrollment_status_v2', params),
	get_enrollment_status_new: params =>
		requestGet('common/get_enrollment_status/1', params),
	clear_section_unit: params =>
		requestNew('section/clear_section_unit', params),
	get_event: params =>
		requestNew('event/get_event', params),
	delete_event: params =>
		requestNew('event/delete', params),
	get_interactive_sources: params =>
		requestNew('online_content/get_interactive_sources', params),
	get_section_unit_detail: params =>
		requestNew('section/get_section_unit_detail', params),
	delete_section_unit: params =>
		requestDELETE('section/delete_section_unit', params),
	get_attendances_count: params =>
		requestNew('section/get_attendances_count', params),
	delete_section_activity: params =>
		requestDELETE('section/delete_section_activity', params),	
	reorder_gradebook_column: params =>
		requestNew('section/reorder_gradebook_column', params),
	get_gradebook_zip: (params) => 
		requestNew('activitytype/get_gradebook_zip',params),
		
				
}
const admin={
	get_upcoming_events: params =>
		requestNew('schooladmin/upcoming_events', params),
	save_events: params =>
		submitformNewPostForm('event/save', params),
	get_section_status: params =>
		requestGet('common/get_section_status', params),
	get_section_weeks: params =>
		requestGet('common/get_section_weeks', params),
	get_active_departments: params =>
		requestGet('department/get_active_departments', params),
	post_active_departments: params =>
		requestNew('department/get_active_departments', params),
	get_section_credits: params =>
		requestGet('common/get_section_credits', params),
	get_section_types: params =>
		requestGet('common/get_section_types', params),
	get_section_levels: params =>
		requestGet('common/get_section_levels', params),
	get_all_daily_periods: params =>
		requestGet('section/get_all_daily_periods', params),
	get_days_of_week: params =>
		requestGet('common/get_days_of_week', params),
	get_current_period: params =>
		requestNew('sectionperiod/get_current_period', params),
	get_inclass_type: params =>
		requestGet('common/get_inclass_type', params),		
	get_available_teachers: params =>
		requestNew('teacher/get_available_teachers', params),		
	get_sections: params =>
		requestNew('schooladmin/get_sections', params),
	get_students: params =>
		requestNew('schooladmin/get_students', params),
	get_teachers: params =>
		requestNew('schooladmin/get_teachers', params),
	get_list_applications: (page,params) =>
		requestNew('application/get_list_applications/'+page, params),
	get_home_overview: (params) =>
		requestGet('schooladmin/home_overview', params),
	get_by_department: (params) =>
		requestNew('facility/get_by_department', params),
	save_section: (params) =>
		submitformNewPostForm('section/save_section', params),	
	get_editrequest_list: (params) =>
		requestNew('request/get_editrequest_list', params),
	get_editrequest_detail: (params) =>
		requestNew('request/get_editrequest_detail', params),
	get_transcriptrequest_detail: (params) =>
		requestNew('transcript/get_transcriptrequest_detail', params),
	get_section_select: (params) =>
		requestNew('section/section_select', params),
	get_section_request: (params) =>
		requestNew('section/section_request', params),
	save_edit_request_view: (params) =>
		submitformNewPostForm('request/save_edit_request_view', params),
	save_withdrawal_request: (params) =>
		requestNew('request/save_withdrawal_request', params),
	save_extension_request: (params) =>
		requestNew('request/save_extension_request', params),
	check_extension_request: (params) =>
		requestNew('request/check_extension_request', params),
	save_enrollment_request: (params) =>
		requestNew('request/save_enrollment_request', params),
	bulk_approve_enrollment: (params) =>
		requestNew('request/bulk_approve_enrollment', params),
	approve_request: (params) =>
		requestNew('request/approve_request', params),
	reject_request: (params) =>
		requestNew('request/reject_request', params),
	delete_request: (params) =>
		requestDELETE('request/delete_request', params),				
	get_editing_section: (params) =>
		requestNew('section/get_editing_section', params),
	remove_section_dailyperiod: (params) =>
		requestNew('section/remove_section_dailyperiod', params),
	get_school_detail: (params) =>
		requestNew('school/get_school_detail', params),
	save_school: (params) =>
		submitformNewPostForm('school/save_school', params),
	save_school_setting: (params) =>
		requestNew('school/save_school_setting', params),
	get_enrollment_rules: (params) =>
		requestNew('school/get_enrollment_rules', params),
	save_enrollment_rules: (params) =>
		requestNew('school/save_enrollment_rules', params),
	get_schooladmin_detail: (params) =>
		requestNew('schooladmin/get_detail', params),
	get_designated_registrar: (params) =>
		requestGet('schooladmin/get_designated_registrar', params),
	get_mentor_role: (params) =>
		requestNew('schooladmin/get_mentor_role', params),
	save_school_admin: (params) =>
		submitformNewPostForm('schooladmin/save', params),
	save_school_misc: (params) =>
		submitformNewPostForm('school/save_school_misc', params),
	get_countries: params =>
		requestGet('common/countries', params),
	
	get_s_countries: (params) =>
		requestNew('common/get_s_countries', params),	
	get_s_states: (params) =>
		requestNew('common/get_s_states', params),	
	get_s_cities: (params) =>
		requestNew('common/get_s_cities', params),	

	add_student: (params) =>
	 submitformNewPostForm('student/add', params),			
	get_student_by_id: (params) =>
		requestNew('student/get_student_by_id', params),
	get_student_by_school: (params) =>
		requestNew('student/get_student_by_school', params),
	edit_student: (params) =>
	 submitformNewPostForm('student/edit', params),	
	get_ability: (params) =>
		requestNew('school/get_ability', params),
	save_ability: (params) =>
		requestNew('school/save_ability', params),
	get_teacher_info: (params) =>
		requestNew('teacher/view', params),
	get_school_admins: (params) =>
		requestNew('schooladmin/get_list', params),
	remove_school_admin: (params) =>
		requestNew('schooladmin/remove', params),
	get_communication_rules: (params) =>
		requestNew('communication/get_communication_rules', params),
	get_comrule_detail: (params) =>
		requestNew('communication/get_comrule_detail', params),
	save_comrule_detail: (params) =>
		requestNew('communication/save_comrule_detail', params),
	delete_comrule: (params) =>
		requestDELETE('communication/delete_comrule', params),
	get_featureconfigs: (params) =>
		requestNew('featureconfig/get_featureconfigs', params),
	get_featureconfig_detail: (params) =>
		requestNew('featureconfig/get_detail', params),
	save_featureconfig: (params) => 
		requestNew('featureconfig/save', params),
	delete_featureconfig: (params) =>
		requestDELETE('featureconfig/delete', params),
	get_list_transcriptrequest: (params) =>
		requestNew('transcript/get_list', params),
	get_transcript_status: (params) =>
		requestGet('transcript/get_status', params),
	change_status_transcriptrequest: (params) =>
		requestNew('transcript/change_status', params),
	archive_transcriptrequest: (params) =>
		requestNew('transcript/archive', params),
	restore_transcriptrequest: (params) =>
		requestNew('transcript/restore', params),
	delete_transcriptrequest: (params) =>
		requestDELETE('transcript/delete', params),
	get_list_attributes: (params) =>
		requestNew('attribute/get_list', params),
	get_attribute_detail: (params) =>
		requestNew('attribute/get_detail', params),
	delete_attribute: (params) =>
		requestDELETE('attribute/delete', params),
	save_attribute: (params) =>
		requestNew('attribute/save_attribute', params),		
	get_additional_fields: (params) =>
		requestNew('attribute/get_additional_fields', params),		
	get_contractrules: (params) =>
		requestNew('contract/get_contractrules', params),
	get_contractrule_detail: (params) =>
		requestNew('contract/get_contractrule_detail', params),
	save_contractrule: (params) =>
		requestNew('contract/save_contractrule', params),
	delete_contractrule: (params) =>
		requestDELETE('contract/delete_contractrule', params),
	save_is_create_contract: (params) =>
		requestNew('contract/save_is_create_contract', params),

	get_contract_templates: (params) =>
		requestNew('contracttemplate/get_contract_templates', params),
	get_contract_template_select: (params) =>
		requestNew('contracttemplate/get_contract_template_select', params),
	get_contracttemplate_detail: (params) =>
		requestNew('contracttemplate/get_detail', params),
	save_contract_template: (params) =>
		requestNew('contracttemplate/save', params),
	delete_contract_template: (params) =>
		requestDELETE('contracttemplate/delete', params),

	get_contract_user_select: (params) =>
		requestNew('contract/get_contract_user_select', params),
	get_student_by_contract_user: (params) =>
		requestNew('contract/get_student_by_contract_user', params),
	get_contract_user_list: (params) =>
		requestNew('contract/get_contract_user_list', params),
	get_contract_user: (params) =>
		requestNew('contract/get_contract_user', params),
	get_contract_user_processing: (params) =>
		requestNew('contract/get_contract_user_processing', params),
	get_contract_user_detail_list: (params) =>
		requestNew('contract/get_contract_user_detail_list', params),
	get_contract_user_detail: (params) =>
		requestNew('contract/get_contract_user_detail', params),
	view_contract_content: (params) =>
		requestNew('contract/view_contract_content', params),
	get_payers: (params) =>
		requestNew('contract/get_payers', params),
	save_contract_user: (params) =>
		requestNew('contract/save_contract_user', params),
	save_contract_user_detail: (params) =>
		requestNew('contract/save_contract_user_detail', params),
	accept_contract: (params) =>
		requestNew('contract/accept_contract', params),
	save_view_contract: (params) =>
		requestNew('contract/save_view_contract', params),
	delete_contract_user: (params) =>
		requestDELETE('contract/delete_contract_user', params),
	delete_contract_user_detail: (params) =>
		requestDELETE('contract/delete_contract_user_detail', params),

	get_b2bcontract: (params) =>
		requestNew('contract/get_b2bcontract', params),
	get_b2bcontract_detail: (params) =>
		requestNew('contract/get_b2bcontract_detail', params),
	get_payers: (params) =>
		requestNew('contract/get_payers', params),
	save_b2bcontract: (params) =>
		requestNew('contract/save_b2bcontract', params),
	delete_b2bcontract: (params) =>
		requestDELETE('contract/delete_b2bcontract', params),
		
	get_master_contract_list: (params) =>
		requestNew('mastercontract/get_master_contract_list', params),
	get_master_contract: (params) =>
		requestNew('mastercontract/get_master_contract', params),
	get_master_contract_select: (params) =>
		requestNew('mastercontract/get_master_contract_select', params),
	get_master_contract_price_unit: (params) =>
		requestNew('mastercontract/get_master_contract_price_unit', params),
	save_master_contract: (params) =>
		requestNew('mastercontract/save_master_contract', params),
	copy_master_contract_to_contract_user: (params) =>
		requestNew('mastercontract/copy_master_contract_to_contract_user', params),
	delete_master_contract: (params) =>
		requestDELETE('mastercontract/delete_master_contract', params),
	get_master_contract_detail_list: (params) =>
		requestNew('mastercontract/get_master_contract_detail_list', params),
	get_master_contract_detail: (params) =>
		requestNew('mastercontract/get_master_contract_detail', params),
	save_master_contract_detail: (params) =>
		requestNew('mastercontract/save_master_contract_detail', params),
	delete_master_contract_detail: (params) =>
		requestDELETE('mastercontract/delete_master_contract_detail', params),

	get_facilities: (params) =>
		requestNew('facility/get_facilities', params),
	get_departments_facilities: (params) =>
		requestGet('facility/get_departments', params),
	get_facility_detail: (params) =>
		requestNew('facility/get_detail', params),
	save_facility: (params) =>
		requestNew('facility/save', params),
	delete_facility: (params) =>
		requestDELETE('facility/delete', params),
	get_all_department: (params) =>
		requestNew('department/get_all', params),
	get_list_department: (params) =>
		requestNew('department/get_list', params),
	get_department_detail: (params) =>
		requestNew('department/get_detail', params),
	get_dep_heads: (params) =>
		requestNew('department/get_dep_heads', params),
	get_global_dep: (params) =>
		requestGet('department/get_global_dep', params),
	save_department: (params) =>
		requestNew('department/save', params),
	delete_department: (params) =>
		requestDELETE('department/delete', params),

	get_ilp_templates: (params) =>
		requestNew('ilp/get_ilp_templates', params),
	get_ilp_detail: (params) =>
		requestNew('ilp/get_ilp_detail', params),
	get_ilpu_detail: (params) =>
		requestNew('ilp/get_ilpu_detail', params),
	get_courses_template: (params) =>
		requestNew('ilp/get_courses_template', params),
	get_courses_ilpdepartment: (params) =>
		requestNew('ilp/get_courses_ilpdepartment', params),
	get_courses_enrollment: (params) =>
		requestNew('ilp/get_courses_enrollment', params),
	get_courses_transfer: (params) =>
		requestNew('ilp/get_courses_transfer', params),
	get_ilp_byid: (params) =>
		requestNew('ilp/get_ilp_byid', params),
	save_ilp: (params) =>
		requestNew('ilp/save_ilp', params),
	update_ilp_detail_order: (params) =>
		requestNew('ilp/update_ilp_detail_order', params),
	update_ilp_detail_year: (params) =>
		requestNew('ilp/update_ilp_detail_year', params),
	update_ilpu_detail: (params) =>
		requestNew('ilp/update_ilpu_detail', params),
	update_ilpu_detail_order: (params) =>
		requestNew('ilp/update_ilpu_detail_order', params),
	add_template_requirement: (params) =>
		requestNew('ilp/add_template_requirement', params),
	remove_template_requirement: (params) =>
		requestDELETE('ilp/remove_template_requirement', params),
	save_ilp_detail: (params) =>
		requestNew('ilp/save_ilp_detail', params),
	save_ilpu_detail: (params) =>
		requestNew('ilp/save_ilpu_detail', params),
	save_ilpu_section: (params) =>
		requestNew('ilp/save_ilpu_section', params),
	duplicate_ilp: (params) => 
		requestNew('ilp/duplicate_ilp', params),
	delete_ilp: (params) => 
		requestDELETE('ilp/delete_ilp', params),
	reset_ilp_detail: (params) => 
		requestDELETE('ilp/reset_ilp_detail', params),
	delete_ilpu_detail: (params) => 
		requestDELETE('ilp/delete_ilpu_detail', params),
	remove_ilpu_section: (params) => 
		requestDELETE('ilp/remove_ilpu_section', params),
	remove_ilpu_detail: (params) =>
		requestDELETE('ilp/remove_ilpu_detail', params),
	reset_ilpu_detail: (params) =>
		requestDELETE('ilp/reset_ilpu_detail', params),
	reset_all_ilpu: (params) =>
		requestDELETE('ilp/reset_all_ilpu', params),
	reset_department_ilpu: (params) =>
		requestDELETE('ilp/reset_department_ilpu', params),

	get_list_academictracks: (params) =>
		requestNew('academictrack/get_list', params),
	get_all_academictracks: (params) =>
		requestGet('academictrack/get_all', params),
	get_all_academictracks: (params) =>
		requestNew('academictrack/get_all', params),
	get_academictrack_detail: (params) =>
		requestNew('academictrack/get_detail', params),
	save_academictrack: (params) =>
		requestNew('academictrack/save', params),
	duplicate_academictrack: (params) =>
		requestNew('academictrack/duplicate', params),
	delete_academictrack: (params) =>
		requestDELETE('academictrack/delete', params),
	get_trackdepartment_list: (params) =>
		requestNew('academictrack/get_trackdepartment_list', params),
	get_requirementgroup: (params) =>
		requestNew('academictrack/get_requirementgroup', params),
	get_trackdepartment: (params) =>
		requestNew('academictrack/get_trackdepartment', params),
	save_trackdepartment: (params) =>
		requestNew('academictrack/save_trackdepartment', params),
	save_requirementgroup: (params) =>
		requestNew('academictrack/save_requirementgroup', params),
	delete_academictrackdepartment: (params) =>
		requestDELETE('academictrack/delete_academictrackdepartment', params),
	save_transcriptcomment: (params) => 
		requestNew('comment/save_transcriptcomment',params),	
	approve_comment: (params) =>
		requestNew('comment/approve_comment', params),
	reject_comment: (params) =>
		requestNew('comment/reject_comment', params),

	get_invoice_list: (params) =>
		requestNew('invoice/get_invoice_list', params),
	get_invoice: (params) =>
		requestNew('invoice/get_invoice', params),
	get_invoice_detail_list: (params) =>
		requestNew('invoice/get_invoice_detail_list', params),
	get_invoice_detail: (params) =>
		requestNew('invoice/get_invoice_detail', params),
	save_invoice: (params) =>
		requestNew('invoice/save_invoice', params),
	save_invoice_detail: (params) =>
		requestNew('invoice/save_invoice_detail', params),
	generate_invoices: (params) =>
		requestNew('invoice/generate_invoices', params),
	save_arconfiguration: (params) =>
		requestNew('invoice/save_arconfiguration', params),
	get_arconfiguration: (params) =>
		requestNew('invoice/get_arconfiguration', params),
	automatic_payment: (params) =>
		requestNew('payment/automatic_payment', params),
	send_late_notices: (params) =>
		requestNew('payment/send_late_notices', params),
	get_user_openbalance: (params) =>
		requestNew('payment/get_user_openbalance', params),
	pay_invoices: (params) =>
		requestNew('payment/pay_invoices', params),
	unapply_payment: (params) =>
		requestNew('payment/unapply_payment', params),
	unapply_invoice: (params) =>
		requestNew('payment/unapply_invoice', params),
	unapply_invoice_detail: (params) =>
		requestNew('payment/unapply_invoice_detail', params),
	refund_payment: (params) =>
		requestNew('payment/refund_payment', params),
	refund_invoice: (params) =>
		requestNew('payment/refund_invoice', params),
	void_invoice: (params) =>
		requestNew('payment/void_invoice', params),
	write_off_invoice: (params) =>
		requestNew('payment/write_off_invoice', params),
	refund_invoice_detail: (params) =>
		requestNew('payment/refund_invoice_detail', params),
	get_paymenthistory_list: (params) =>
		requestNew('payment/get_list', params),
	get_payment_credit: (params) =>
		requestNew('payment/get_payment_credit', params),
	save_payment_profile: (params) =>
		requestNew('payment/save_payment_profile', params),
	save_payment_config: (params) =>
		requestNew('payment/save_payment_config', params),
	get_payment_config: (params) =>
		requestNew('payment/get_payment_config', params),
	agree_terms: (params) =>
		requestNew('payment/agree_terms', params),
	get_payment_terms_of_use: (params) =>
		requestGet('payment/get_terms_of_use', params),
	delete_invoice: (params) =>
		requestDELETE('invoice', params),

	get_product_list: (params) =>
		requestNew('product/get_list', params),
	get_product_select: (params) =>
		requestNew('product/get_product_select', params),
	get_product_detail: (params) =>
		requestNew('product/get_detail', params),
	save_product: (params) =>
		requestNew('product/save', params),
	delete_product: (params) =>
		requestDELETE('product', params),

	get_productsku_list: (params) =>
		requestNew('product/get_sku_list', params),
	get_productsku_detail: (params) =>
		requestNew('product/get_sku_detail', params),
	save_productsku: (params) =>
		requestNew('product/save_sku', params),
	delete_productsku: (params) =>
		requestDELETE('product/delete_sku', params),

	get_templates_list: (params) =>
		requestNew('notifications/get_templates_list', params),
	get_notification_template: (params) =>
		requestNew('notifications/get_notification_template', params),
	save_notification_template: (params) =>
		requestNew('notifications/save_notification_template', params),
	delete_notification_template: (params) =>
		requestDELETE('notifications/delete_notification_template', params),
	get_event_type: (params) =>
		requestGet('notifications/get_event_type', params),

	get_featuresettings: (params) =>
		requestNew('featureconfig/get_featuresettings', params),
	save_featuresettings: (params) =>
		requestNew('featureconfig/save_featuresettings', params),

	get_badge_list: (params) =>
		requestNew('badge/get_list', params),
	get_badge_detail: (params) =>
		requestNew('badge/get_detail', params),
	save_badge: (params) =>
		submitformNewPostForm('badge/save', params),
	delete_badge: (params) =>
		requestDELETE('badge/delete', params),

	get_objectstate_list: (params) =>
		requestNew('objectstate/get_list', params),
	get_object_for_changing: (params) =>
		requestNew('objectstate/get_object_for_changing', params),
	save_objectstate: (params) =>
		requestNew('objectstate/save', params),
	get_objectstate_detail: (params) =>
		requestNew('objectstate/get_detail', params),
	delete_objectstate: (params) =>
		requestDELETE('objectstate', params),
	get_rule_select: (params) =>
		requestGet('objectstate/get_rule_select', params),
	get_status_select: (params) =>
		requestNew('statuses/get_status_select', params),
	get_status_list: (params) =>
		requestNew('statuses/get_list', params),
	get_applyto_list: (params) =>
		requestNew('statuses/get_applyto_list', params),
	get_status_detail: (params) =>
		requestNew('statuses/get_detail', params),
	save_status: (params) =>
		requestNew('statuses/save', params),
	delete_status: (params) =>
		requestDELETE('statuses', params),

	save_school_comment: params =>
		submitformNewPostForm('comment/save_school_comment', params),
	get_school_comments: (params) =>
		requestNew('comment/get_school_comments', params),
	get_school_comment_detail: (params) =>
		requestNew('comment/school_comment_detail', params),	
	delete_school_comment: (params) =>
		requestDELETE('comment/delete_school_comment', params),	

	get_list_gradingperiods: (params) =>
		requestNew('gradingperiod/get_list', params),
	get_gradingperiod_detail: (params) =>
		requestNew('gradingperiod/get_detail', params),
	save_gradingperiod: (params) =>
		requestNew('gradingperiod/save', params),
	delete_gradingperiod: (params) =>
		requestDELETE('gradingperiod/delete', params),
	get_list_gradingscales: (params) =>
		requestNew('gradingscale/get_list', params),
	get_gradingscale_detail: (params) =>
		requestNew('gradingscale/get_detail', params),
	save_gradingscale: (params) =>
		requestNew('gradingscale/save', params),
	delete_gradingscale: (params) =>
		requestDELETE('gradingscale/delete', params),
	get_list_academicterms: (params) =>
		requestNew('sectionperiod/get_list', params),
	get_closing_days: (params) =>
		requestNew('sectionperiod/get_closing_days', params),
	get_academicterm_detail: (params) =>
		requestNew('sectionperiod/get_detail', params),
	save_academicterm: (params) =>
		requestNew('sectionperiod/save', params),
	save_closing_days: (params) =>
		requestNew('sectionperiod/save_closing_days', params),
	delete_academicterm: (params) =>
		requestDELETE('sectionperiod/delete', params),
	delete_closing_days: (params) =>
		requestDELETE('sectionperiod/delete_closing_days', params),
	get_list_bellschedules: (params) =>
		requestNew('dailyperiod/get_list', params),
	get_bellschedule_detail: (params) =>
		requestNew('dailyperiod/get_detail', params),
	save_bellschedule: (params) =>
		requestNew('dailyperiod/save', params),
	delete_bellschedule: (params) =>
		requestDELETE('dailyperiod/delete', params),
	get_list_activitytype: (params) =>
		requestNew('activitytype/get_list', params),
	get_activitytype_detail: (params) =>
		requestNew('activitytype/get_detail', params),
	save_activitytype: (params) =>
		requestNew('activitytype/save', params),
	delete_activitytype: (params) =>
		requestDELETE('activitytype/delete', params),
	get_users: (params) =>
		requestNew('user/get_users', params),
	get_courses: (params) =>
		requestNew('schooladmin/get_courses', params),
	get_course_status: (params) =>
		requestGet('common/get_course_status', params),
	get_user_tags: (params) =>
		requestNew('user/get_user_tags', params),
	update_user_tags: (params) =>
		requestNew('user/update_user_tags', params),
	get_online_courses: (params) =>
		requestNew('online_content/get_online_courses', params),
	get_content_sources: (params) =>
		requestNew('online_content/get_content_sources', params),
	save_course: (params) =>
		submitformNewPostForm('course/save_course', params),
	delete_course: (params) =>
		requestDELETE('course/delete_course', params),
	get_application_byid: (id,params) =>
		requestGet('application/get_application_byid/'+id, params),
	get_editing_course: (params) =>
		requestNew('course/get_editing_course', params),
	get_program_of_interest: (params) =>
		requestGet('application/program_of_interest', params),
	add_application: (params) =>
		requestPayLoad('application/add_application', params),
	update_application: (params) =>
		requestPayLoad('application/update_application', params),	
	delete_application: (params) =>
	  	requestDELETE('application/delete_application', params),	
	get_enrollable_students: (params) =>
		requestNew('section/get_enrollable_students', params),	
	enroll_students: (params) =>
		submitformNewPostForm('enrollment/enroll_students', params),
	enroll_student: (params) =>
		submitformNewPostForm('enrollment/enroll_student', params),
	add_teacher: (params) =>
		submitformNewPostForm('teacher/add', params),	
	delete_section: (params) =>
	    requestDELETE('section/delete_section', params),	
	save_other_grade: (params) =>
		submitformNewPostForm('othergrade/save_other_grade', params),
	get_other_grade: (params) =>
		requestNew('othergrade/get_other_grade', params),
	delete_order: (params) =>
		requestNew('othergrade/delete', params),
	get_edit_logs: (params) =>
		requestNew('user/get_edit_logs', params),
	get_audit_log: (params) => 
		requestNew('auditlog/get_list',params),
	get_relationships: (params) =>
		requestGet('parent/get_relationships', params),
	add_parent: (params) =>
		requestNew('parent/add', params),
	get_list_parent: (params) =>
		requestNew('parent/get_list', params),	
	delete_parent: (params) =>
		requestNew('parent/delete', params),	
	view_parent: (params) =>
		requestNew('parent/view', params),
	edit_parent: (params) =>
		submitformNewPostForm('parent/edit', params),
	save_other_grades: (params) =>
		submitformNewPostForm('othergrade/save_other_grades', params),
	get_user_schools: (params) =>
		requestNew('user/get_user_schools', params),
	get_status_option: (params) =>
		requestNew('user/get_status_option', params),	
	delete_teacher: (params) =>
		requestNew('teacher/delete', params),	
	delete_student: (params) =>
		requestNew('student/delete', params),
	get_promote_students: (params) =>
		requestNew('schooladmin/get_promote_students', params),
	promote_students: (params) =>
		submitformNewPostForm('schooladmin/promote_students', params),
	edit_teacher: (params) =>
		submitformNewPostForm('teacher/edit', params),
	home_student_chart: (params) =>
		requestNew('schooladmin/home_student_chart', params),
	home_attendance_chart: (params) =>
		requestGet('schooladmin/home_attendance_chart', params),
	home_department_chart: (params) =>
		requestGet('schooladmin/home_department_chart', params),
	get_qualifications: (params) =>
		requestNew('teacher/get_qualifications', params),
	teacher_save_qualification: (params) =>
		requestNew('teacher/save_qualification', params),
	get_qualification_view: (params) =>
		requestNew('teacher/get_qualification_view', params),
	delete_qualification: (params) =>
		requestNew('teacher/delete_qualification', params),
	get_teacher_option: (params) =>
		requestNew('teacher/get_teacher_option', params),
	get_active_departments_op: params =>
		requestNew('department/get_active_departments', params),
	get_options_learning_path: params =>
		requestGet('schooladmin/get_options_learning_path', params),
	get_learning_path_template: params =>
		requestNew('schooladmin/learning_path_template', params),
	get_sections_of_course: params =>
		requestNew('course/get_sections', params),
	get_course_info: params =>
		requestNew('course/get_course_info', params),
	get_teacher_online_sections: params =>
		requestNew('schooladmin/get_teacher_online_sections', params),
	get_course_students: params =>
		requestNew('course/get_students', params),
	export_students: params =>
		requestNew('schooladmin/export_students', params),
	get_object_comments: params =>
		requestNew('comment/get_object_comments', params),
	get_courses_select: params =>
		requestNew('course/courses_select', params),
	get_prerequisites: params =>
		requestNew('course/get_prerequisites', params),
	export_parents: params =>
		requestNew('schooladmin/export_parents', params),
	get_multiple_enroll_get_teachers: params =>
		submitformNewPostForm('section/multiple_enroll_get_teachers', params),
	add_teachers_to_sec: params =>
		submitformNewPostForm('section/add_teachers', params),
	get_multiple_enroll_get_current_teachers: params =>
		submitformNewPostForm('section/multiple_enroll_get_current_teachers', params),
	remove_teachers: params =>
		submitformNewPostForm('section/remove_teachers', params),
	export_sections: params =>
		submitformNewPostForm('schooladmin/export_sections', params),
	active_courses: params =>
		submitformNewPostForm('course/active_courses', params),
	inactive_courses: params =>
		submitformNewPostForm('course/inactive_courses', params),
	set_finalize_sections: params =>
		submitformNewPostForm('section/finalize_sections', params),
	export_sections_pdf: params =>
		submitformNewPostForm('schooladmin/export_sections_pdf', params),
	get_user_overview: params =>
		requestNew('user/user_overview', params),
	save_user_school: params =>
		submitformNewPostForm('user/save_user_school', params),
	export_users: params =>
		requestNew('user/export_users', params),
	export_users_pdf: params =>
		requestNew('user/export_users_pdf', params),
	get_by_student_section: params =>
		requestNew('enrollment/get_by_student_section', params),
	verify_student: params =>
		requestNew('enrollment/verify_student', params),
	load_bulk_file: params =>
		submitformNewPostForm('enrollment/load_bulk_file', params),
	export_teachers: params =>
		requestNew('schooladmin/export_teachers', params),
	export_teachers_pdf: params =>
		requestNew('schooladmin/export_teachers_pdf', params),
	send_mail_to_user: params =>
		requestNew('common/send_mail', params),
	change_username_password: params =>
		requestNew('user/change_username_password', params),
	impersonate: params =>
		requestNew('user/impersonate', params),
	unimpersonate: params =>
		requestNew('user/unimpersonate', params),
	upload_sections: params =>
		submitformNewPostForm('section/upload_sections', params),
	add_prerequisite: params =>
		submitformNewPostForm('course/add_prerequisite', params),
	delete_prerequisite: params =>
		requestDELETE('course/delete_prerequisite', params),
	delete_user_school: params =>
		requestDELETE('user/delete_user_school', params),
	app_change_status: params =>
		requestNew('application/change_status', params),
	archived_application: params =>
		submitformNewPostForm('application/archived_application', params),
	set_expiration_date: params =>
		requestNew('user/set_expiration_date', params),
	get_statuses_user: params =>
		requestNew('user/get_statuses', params),
	get_status_changing: params =>
		requestNew('user/get_status_changing', params),
	change_status_user: params =>
		requestNew('user/change_status', params),
	upload_applications: params =>
		submitformNewPostForm('application/upload_applications', params),
	open_sections: params =>
		submitformNewPostForm('section/open_sections', params),
	close_sections: params =>
		submitformNewPostForm('section/close_sections', params),
	delete_enrollment: params =>
		requestDELETE('enrollment/delete_enrollment', params),
	delete_enrollments: params =>
		requestDELETE('enrollment/delete_enrollments', params),
	get_user_by_id: params =>
		requestNew('user/get_user_by_id', params),
	send_email_schedule: params =>
		requestNew('student/send_email_schedule', params),
	get_content_sources: params =>
		requestNew('online_content/get_content_sources', params),
	get_available_teachers: params =>
		requestNew('teacher/get_available_teachers', params),
	get_transfer_sections: params =>
		submitformNewPostForm('section/get_transfer_sections', params),
	transfer_students: params =>
		submitformNewPostForm('student/transfer_students', params),
	get_editing_enrollment: (params) =>
		requestNew('enrollment/get_editing_enrollment', params),
	get_enrollment_history: (params) =>
		requestNew('enrollment/get_enrollment_history', params),
	save_enrollment: (params) =>
		submitformNewPostForm('enrollment/save_enrollment', params),
	get_option_transcript: (params) =>
		requestNew('student/get_option_transcript', params),
	save_option_transcript: (params) =>
		submitformNewPostForm('student/save_option_transcript', params),	
	upload_student: params =>
		submitformNewPostForm('student/import', params),
	get_student_in_parent: (params) =>
		requestNew('parent/get_students', params),
	get_student_link: (params) =>
		requestNew('schooladmin/get_students', params),
	link_students: params =>
		requestNew('parent/link_students', params),	
	unlink_students: params =>
		requestNew('parent/unlink_students', params),	
	get_students_existing: params =>
		requestNew('application/get_students_existing', params),
	merge_application_to_student: params =>
		requestNew('application/merge_application_to_student', params),
	enrollment_change_status: (params) =>
		submitformNewPostForm('enrollment/change_status', params),
	get_reconlize_students: params =>
		requestNew('student/get_reconlize_students', params),
	reconlize_another_student: params =>
		requestNew('student/reconlize_another_student', params),
	print_schedule_student: params =>
		requestNew('student/print_schedule', params),
	get_options_learning_path: (params) =>
		requestGet('schooladmin/get_options_learning_path', params),
	assign_student_lpt: (params) =>
		requestNew('schooladmin/assign_student_lpt', params),
	get_application_states: (app_id) => 
		requestGet('application/application_states/'+app_id),
	get_rosters: (params) =>
		requestNew('schooladmin/get_rosters', params),
	get_offline_activity_template: (params) =>
		requestNew('section/get_offline_activity_template', params),
	get_count_new_objects: (params) =>
		requestGet('data/get_count_new_objects', params),
	get_sections_internal_transfer: (params) =>
		requestNew('section/get_sections_internal_transfer', params),
	internal_transfer: (params) =>
		requestNew('section/internal_transfer', params),
	get_object_detail: (params) =>
		requestNew('comment/object_detail', params),
	get_content_user_roles: (params) =>
		requestNew('online_content/get_content_user_roles', params),
	get_content_roles: (params) =>
		requestNew('online_content/get_content_roles', params),
	create_content_user_role: (params) =>
		requestNew('online_content/create_content_user_role', params),
	get_content_roles_sso: (params) =>
		requestNew('user/get_content_roles_sso', params),
	delete_content_user_role: (params) =>
		requestDELETE('online_content/delete_content_user_role', params),
	get_based_tags: (params) =>
		requestNew('security/get_based_tags', params),
	get_configuration: (params) =>
		requestGet('user/configuration', params),
	get_school_based_tags: (params) =>
		requestNew('security/get_school_based_tags', params),
	get_roles_tags: (params) =>
		requestNew('security/get_roles_tags', params),
	add_role_tags: (params) =>
		requestNew('security/add_role_tags', params),
	view_role_tags: (params) =>
		requestNew('security/view_role_tags', params),
	edit_role_tags: (params) =>
		submitformNewPostForm('security/edit_role_tags', params),
	sync_and_update: (params) =>
		requestNew('student/sync_and_update', params),
	get_ilp_by_courseid: (params) =>
		submitformNewPostForm('ilp/get_ilp_by_courseid', params),
	add_multiple_for_comments: (params) =>
		submitformNewPostForm('comment/add_multiple', params),
	add_multiple_for_contract: (params) =>
		submitformNewPostForm('comment/add_multiple_for_contract', params),
	send_login_credentials: (params) =>
		submitformNewPostForm('user/send_login_credentials', params),	
	get_student_idcard: (params) =>
		requestNew('student/get_student_idcard', params),
	trigger_notifications: (params) =>
		submitformNewPostForm('notifications/trigger', params),
	upload_summaries: params =>
		submitformNewPostForm('section/upload_summaries', params),
	get_global_sections: (params) =>
		requestNew('section/get_global_sections', params),
	get_genders: params =>
		requestGet('common/get_genders', params),	
	enrollment_change_enroll_status: params =>
		submitformNewPostForm('enrollment/change_enroll_status', params),
	save_academic_records: (params) =>
		requestNew('student/save_academic_records', params),
	get_universal_users: (params) =>
		requestNew('user/get_universal_users', params),
	get_user_info_online_content: (params) => 
		requestNew('online_content/get_user_info',params),	
	get_new_apex_classrooms: (params) => 
		requestNew('student/get_new_apex_classrooms',params),
	import_new_apex_classrooms: (params) => 
		requestNew('student/import_new_apex_classrooms',params),
	get_templates_upload: (params) => 
		requestGet('data/get_templates',params),
	get_template_view_upload: (params) => 
		requestNew('data/get_template_view',params),
	save_import_template: params =>
		requestNew('data/save_import_template', params),
	get_dropdown_fields: (params) => 
		requestGet('data/get_dropdown_fields',params),
	show_datas_file: params =>
		submitformNewPostForm('data/show_datas_file', params),
	load_import_template: params =>
		submitformNewPostForm('data/load_import_template', params),
	get_user_group_tags: (params) =>
		requestNew('security/get_user_group_tags', params),
	link_parents: params =>
		requestNew('student/link_parents', params),	
	global_section_get_schools: params =>
		requestNew('section/global_section_get_schools', params),	
	print_transcript_zip: params =>
		submitformNewPostForm('schooladmin/transcript_zip', params),
	print_transcript_zip_nd: params =>
		submitformNewPostForm('schooladmin/transcript_zip_nd', params),
	enrollment_mark_as_completed: params =>
		submitformNewPostForm('enrollment/mark_as_completed', params),
	update_section_status: params =>
		requestNew('section/update_section_status', params),	
	load_othergrades: params =>
		submitformNewPostForm('data/load_othergrades', params),
	download_gradebook: params =>
		requestNew('section/download-gradebook', params),
	batch_upload_enrollment: params =>
		submitformNewPostForm('enrollment/batch_upload', params),
	get_certificate_url: params =>
		requestNew('certificate/get_certificate_url', params),	
	get_all_certificate_by_section: params =>
		requestNew('certificate/get_all_certificate_by_section', params),	
	get_all_certificate_by_student: params =>
		requestNew('certificate/get_all_certificate_by_student', params),	
	get_certificate_template: params =>
		requestNew('certificate/get_certificate_template', params),	
	delete_all_othergrade: (params) =>
		submitformNewPostForm('othergrade/delete_all', params),
	get_mentors_of_student: params =>
		requestNew('student/get_mentors', params),
	get_mentor_roles: (params) =>
		requestGet('common/get_mentor_roles', params),
	get_active_staffs_to_link: params =>
		requestNew('student/get_active_staffs_to_link', params),
	link_mentors: params =>
		submitformNewPostForm('student/link_mentors', params),
	unlink_student_mentor: params =>
		requestDELETE('student/unlink_student_mentor', params),
	get_transfer_sections_v2: params =>
		submitformNewPostForm('section/get_transfer_sections_v2', params),	
	transfer_students_v2: params =>
		submitformNewPostForm('student/transfer_students_v2', params),
	app_changeSchoolApplication: params =>
		requestNew('application/change_school', params),
	get_attributes_of_object: params =>
		submitformNewPostForm('attribute/get_attributes_of_object', params),
	save_attributes_students: params =>
		submitformNewPostForm('student/save_attributes_students', params),
	get_list_parents_byemail: (params) =>
		requestNew('application/get_list_parents_byemail', params),
	check_duplicate_username: (params) =>
		requestNew('user/check_duplicate_username', params),
	load_participation_grade: params =>
		submitformNewPostForm('data/load_participation_grade', params),	
	save_object_comment: params => 
		submitformNewPostForm('comment/save_object_comment',params),
	get_comment_type_select: params => 
		requestNew('comment/get_comment_type_select',params),

	get_transcript_default_options: (params) =>
		requestNew('schooladmin/get_transcript_default_options', params),
	getlist_transcript_default_options: (params) =>
		requestNew('schooladmin/getlist_transcript_default_options', params),
	save_transcript_default_options: (params) =>
		requestNew('schooladmin/save_transcript_default_options', params),		
	load_one_roster_users: params =>
		requestGet('data/load_one_roster_users', params),
	get_school_tags: (params) =>
		requestNew('security/get_school_tags', params),
	save_school_tags: (params) =>
		submitformNewPostForm('security/save_school_tags', params),
	zendesksso: (params) =>
		requestNew('common/zendesksso', params),
	get_certificate: (params) =>
		requestGet('certificate/get_all', params),	
	save_sectiont: (params) =>
		submitformNewPostForm('section/save_section', params),	
	get_events_by_user: (params) =>
		requestNew('event/get_events_by_user', params),
	transfer_event: (params) =>
		submitformNewPostForm('event/transfer', params),
	remove_event: (params) =>
		submitformNewPostForm('event/remove', params),
	restore_apex_classrooms: (params) =>
		requestNew('teacher/restore_apex_classrooms', params),
	get_resource_by_user: (params) =>
		requestNew('resource/get_resource_by_user', params),
	transfer_resource: (params) =>
		submitformNewPostForm('resource/transfer', params),
	get_sections_by_teacher: (params) =>
		requestNew('section/get_sections_by_teacher', params),
	transfer_section: (params) =>
		submitformNewPostForm('section/transfer', params),
	get_progress_color_legend: (params) =>
		requestGet('common/get_progress_color_legend', params),
	generic_batch_update: (params) =>
		submitformNewPostForm('data/generic_batch_update', params),
	load_batch_update: (params) =>
		submitformNewPostForm('data/load_batch_update', params),
	batch_update_student: (params) =>
		submitformNewPostForm('data/batch_update', params),
	check_user_update: (params) =>
		requestNew('data/check_user_update', params),
	get_apex_programs: (params) =>
		requestNew('online_content/get_apex_programs', params),	
	copy_courses: params =>
		submitformNewPostForm('course/copy_courses', params),
	save_user_schoolgroup: params =>
		submitformNewPostForm('user/save_user_schoolgroup', params),
	get_address_link: (params) =>
		requestNew('user/get_address_link', params),
	synchronized_address: (params) =>
		submitformNewPostForm('user/synchronized_address', params),	
	get_promote_transfer: (params) =>
		submitformNewPostForm('schooladmin/get_promote_transfer', params),
	do_promote_transfer: (params) =>
		submitformNewPostForm('schooladmin/do_promote_transfer', params),
	assigning_multiple_users_schools: params =>
		submitformNewPostForm('user/assigning_multiple_users_schools', params),
	export_progress_reports: params =>
		submitformNewPostForm('schooladmin/export_progress_reports', params),
	get_selected_user_schools: (params) =>
		submitformNewPostForm('user/get_selected_user_schools', params),
	add_edit_school_assignment: (params) =>
		submitformNewPostForm('user/add_edit_school_assignment', params),
	get_schools_of_schoolgroup: (params) => 
		requestNew('school/get_schools_of_schoolgroup',params),
	batch_update_section_status: params =>
		submitformNewPostForm('section/batch_update_section_status', params),
	archive_user: (params) => 
		submitformNewPostForm('user/archive_user',params),
	unarchive_user: (params) => 
		submitformNewPostForm('user/unarchive_user',params),
	archive_user_current: (params) => 
		submitformNewPostForm('user/archive_user_current ',params),
	get_enrollable_student_filterred_status: params => 
		requestGet('section/get_enrollable_student_filterred_status',params),
	get_object_auditlog_list: params => 
		requestNew('auditlog/get_object_list',params),
}




const reports={
	get_progress_report: params => 
		requestNew('customreport/progress_report/1',params),
	get_application_report: params => 
		requestNew('customreport/application_report/1',params),
	get_teacher_report: params => 
		requestNew('customreport/teacher_report/1',params),
	get_parent_report: params => 
		requestNew('customreport/parent_report/1',params),	
	get_roster_report: params => 
		requestNew('customreport/roster_report/1',params),
	get_section_report: params => 
		requestNew('customreport/section_report/1',params),	
	get_course_report: params => 
		requestNew('customreport/course_report/1',params),
	get_full_progress_report: params => 
		requestNew('customreport/full_progress_report/1',params),
	get_login_credentials_report: params => 
		requestNew('customreport/login_credentials_report/1',params),
	get_last_login_report: params => 
		requestNew('customreport/last_login_report/1',params),
	get_enrollment_report: params => 
		requestNew('customreport/enrollment_report/1',params),
	get_enrollment_summary_report: params => 
		requestNew('customreport/enrollment_summary_report/1',params),
	get_student_contract_report: params => 
		requestNew('customreport/student_contract_report/1',params),
	get_school_information_report: params => 
		requestNew('customreport/school_information_report/1',params),
	get_communication_report: params => 
		requestNew('customreport/communication_report/1',params),
	get_comment_document_report: params => 
		requestNew('customreport/comment_document_report/1',params),
	get_school_calendar_report: params => 
		requestNew('customreport/school_calendar_report/1',params),
	get_planned_closing_days: params => 
		requestNew('customreport/get_planned_closing_days',params),
	get_bell_schedule_report: params => 
		requestNew('customreport/bell_schedule_report/1',params),
	get_attendance_report: params => 
		requestNew('customreport/attendance_report/1',params),
	get_student_enroll_summary_report: params => 
		requestNew('customreport/student_enroll_summary_report/1',params),
	get_grade_report: params => 
		requestNew('customreport/grade_report/1',params),
	get_transcript_report: params => 
		requestNew('customreport/transcript_report/1',params),
	get_invoice_report: params => 
		requestNew('customreport/invoice_report/1',params),
	get_attendance_summary_report: params => 
		requestNew('customreport/attendance_summary_report/1',params),	
	get_student_report: params => 
		requestNew('customreport/student_report/1',params),	
	get_aging_report: params => 
		requestNew('customreport/aging_report/1',params),
	get_transcript_request_report: params => 
		requestNew('customreport/transcript_request_report/1',params),	
		
	//eos report
	get_active_enrollments_per_term: params => 
		requestNew('customreport/active_enrollments_per_term/1',params),
	get_active_student_enrollments_with_as: params => 
		requestNew('customreport/active_student_enrollments_with_as/1',params),	
	get_active_student_enrollments: params => 
		requestNew('customreport/active_student_enrollments/1',params),
	get_all_students_gpa: params => 
		requestNew('customreport/all_students_gpa/1',params),
	get_all_students_gpa_old: params => 
		requestNew('customreport/all_students_gpa_old/1',params),
	get_billing_group_engagement_type: params => 
		requestNew('customreport/billing_group_engagement_type/1',params),
	get_enrollment_confirmation: params => 
		requestNew('customreport/enrollment_confirmation/1',params),
	get_eos_college_matriculations: params => 
		requestNew('customreport/eos_college_matriculations/1',params),
	get_student_enrollment_history_todate_w_term: params => 
		requestNew('customreport/student_enrollment_history_todate_w_term/1',params),	
	get_student_information_report: params => 
		requestNew('customreport/student_information/1',params),		
	//end eos report	

	get_schoolgroups: (params) =>
		requestGet('customreport/get_schoolgroups', params),
	get_schools: (params) =>
		requestGet('customreport/get_schools', params),
	get_studentstatus: (params) =>
		requestGet('customreport/get_studentstatus', params),
	get_gradelevel: (params) =>
		requestGet('customreport/get_gradelevel', params),
	get_teachers: (params) =>
		requestGet('customreport/get_teachers', params),
	get_primary_teachers: (params) =>
		requestGet('customreport/get_primary_teachers', params),
	get_secondary_teachers: (params) =>
		requestGet('customreport/get_secondary_teachers', params),		
	get_departments: (params) =>
		requestGet('customreport/get_departments', params),
	get_courses: (params) =>
		requestGet('customreport/get_courses/Active', params),
	get_sections: (params) =>
		requestGet('customreport/get_sections/open', params),
	get_availability: (params) =>
		requestGet('customreport/get_availability', params),
	get_ethnicity: (params) =>
		requestGet('customreport/get_ethnicity', params),
	get_advisor: (params) =>
		requestGet('customreport/get_advisor', params),
	get_track: (params) =>
		requestGet('customreport/get_track', params),
	get_additional_fields: (params) =>
		requestNew('customreport/get_additional_fields', params),
	get_transferred_from_schools: (params) =>
		requestGet('customreport/get_transferred_from_schools', params),
	get_application_status: (params) =>
		requestGet('customreport/get_application_status', params),
	get_parent_status: (params) =>
		requestGet('customreport/get_parent_status', params),
	get_parent_relationship: (params) =>
		requestGet('customreport/get_parent_relationship', params),
	get_teacher_status: (params) =>
		requestGet('customreport/get_teacher_status', params),
	get_students: (params) =>
		requestGet('customreport/get_students', params),
	get_academic_term: (params) =>
		requestGet('customreport/get_academic_term', params),
	get_report_card: (params) =>
		requestGet('customreport/get_report_card', params),
	get_section_status: (params) =>
		requestGet('customreport/get_section_status', params),
	get_course_status: (params) =>
		requestGet('customreport/get_course_status', params),
	get_closed_courses: (params) =>
		requestGet('customreport/get_closed_courses', params),
	get_course_levels: (params) =>
		requestGet('customreport/get_course_levels', params),
	get_enrollment_status: (params) =>
		requestGet('customreport/get_enrollment_status', params),
	get_student_enrolled: (params) =>
		requestGet('customreport/get_student_enrolled', params),	
			
	get_closed_sections: (params) =>
		requestGet('customreport/get_closed_sections', params),
	get_transferred_from_schools: (params) =>
		requestGet('customreport/get_transferred_from_schools', params),
	get_content_provider: (params) =>
		requestGet('customreport/get_content_provider', params),
	get_user_type_name: (params) =>
		requestGet('customreport/get_user_type_name', params),
	get_contracts: (params) =>
		requestGet('customreport/get_contracts', params),
	get_methods: (params) =>
		requestGet('customreport/get_methods', params),
	get_comment_types: (params) =>
		requestGet('customreport/get_comment_types', params),
	get_flagged: (params) =>
		requestGet('customreport/get_flagged', params),
	get_school_sessions: (params) =>
		requestGet('customreport/get_school_sessions', params),
	get_attendance: (params) =>
		requestGet('customreport/get_attendance', params),
	get_gradebook_type: (params) =>
		requestGet('customreport/get_gradebook_type', params),
	get_specific_mark: (params) =>
		requestGet('customreport/get_specific_mark', params),	
	get_user_by_role_type: params => 
		requestNew('customreport/get_user_by_role_type',params),
	get_list_success_manager: params => 
		requestNew('customreport/get_list_success_manager',params),	
	

}
const superadmin={
	school_get_list: params => 
		requestNew('school/get_list',params),
	get_schoolgroups: params => 
		requestGet('school/get_schoolgroups',params),
	get_time_zone_list: params => 
		requestGet('common/time_zone_list',params),
	get_school_types: params => 
		requestGet('common/get_school_types',params),
	get_schools_select: params => 
		requestNew('school/get_schools_select',params),
	get_schools_by_tag: params => 
		requestNew('school/get_schools_by_tag',params),
	sync_schoolsetup: params => 
		requestNew('schoolgroup/sync_schoolsetup',params),
	delete_school: params => 
		requestDELETE('school/delete_school ',params),
	archive_school: params => 
		requestNew('school/archive_school',params),
	get_schoolgroups: params => 
		requestNew('schoolgroup/get_schoolgroups',params),
	save_schoolgroup: params => 
		requestNew('schoolgroup/save_schoolgroup',params),
	get_configuration_list: params => 
		requestNew('schoolgroup/get_configuration_list',params),
	get_configuration: params => 
		requestNew('schoolgroup/get_configuration',params),
	get_configuration_by_schoolid: params => 
		requestNew('schoolgroup/get_configuration_by_schoolid',params),
	save_configuration_school_group: params => 
		requestNew('schoolgroup/save_configuration_school_group',params),
	delete_config_schoolgroup: params => 
		requestDELETE('schoolgroup/delete_config_schoolgroup',params),
	get_schoolgroup_by_id: params => 
		requestNew('schoolgroup/get_schoolgroup_by_id',params),
	delete_schoolgroup: params => 
		requestDELETE('schoolgroup/delete_schoolgroup',params),
	get_personal_tags: params => 
		requestNew('security/get_personal_tags',params),
	update_personal_tags: params => 
		requestNew('security/update_personal_tags',params),
	get_custom_tags: params => 
		requestNew('security/get_custom_tags',params),
	add_custom_role: params => 
		requestNew('security/add_custom_role',params),
	get_custom_role_tags: params => 
		requestNew('security/get_custom_role_tags',params),
	update_custom_role_tags: params => 
		requestNew('security/update_custom_role_tags',params),
	assign_school_base_tags: params => 
		requestNew('security/assign_school_base_tags',params),
	get_config: params => 
		requestNew('online_content/get_config',params),
	save_config: params => 
		submitformNewPostForm('online_content/save_config',params),
	remove_config: params => 
		requestDELETE('online_content/remove_config',params),
	load_courses: params => 
		submitformNewPostForm('data/load_courses',params),
	get_queues: params => 
		requestNew('notifications/get_queues',params),
	get_notification_rules: params =>
		requestNew('notifications/get_notification_rules', params),
	activate_notification_rules: params =>
		requestNew('notifications/activate_notification_rules', params),
	deactivate_notification_rules: params =>
		requestNew('notifications/deactivate_notification_rules', params),
	delete_notification_rule: params => 
		requestNew('notifications/delete_notification_rule',params),
	get_event_type: params => 
		requestGet('notifications/get_event_type',params),
	get_templates_list: params => 
		requestNew('notifications/get_templates_list',params),
	get_template_by_event: params => 
		requestNew('notifications/get_template_by_event',params),
	save_notification_rule: params => 
		requestNew('notifications/save_notification_rule',params),
	copy_notification_rules: params => 
		requestNew('notifications/copy_notification_rules',params),
	view_notification_rule: params => 
		requestNew('notifications/view_notification_rule',params),
	get_status_notifications: params => 
		requestGet('notifications/get_status',params),
	add_queues: params => 
		submitformNewPostForm('add_queues',params),
	get_queues_view: params => 
		requestNew('notifications/get_queues_view',params),
	save_queue: params => 
		requestNew('notifications/save_queue',params),
	delete_queue: params => 
		requestNew('notifications/delete_queue',params),
	progress_queues: params => 
		requestNew('progress_queues',params),
	get_queues_log: params => 
		requestNew('notifications/get_queues_log',params),
	resend_notification: params => 
		requestNew('notifications/resend',params),
	get_turn_on_notification: (school_id) => 
		requestGet('notifications/get_turn_on_notification/'+school_id),
	get_custom_roles: params => 
		requestNew('security/get_custom_roles',params),
	save_user_role_tag: params => 
		submitformNewPostForm('security/save_user_role_tag',params),
	save_users_custom_tag: params => 
		submitformNewPostForm('security/save_users_custom_tag',params),
	
	get_consolidated_tags: params => 
		requestNew('security/get_consolidated_tags',params),
	get_based_tags_id: params => 
		requestNew('security/get_based_tags_id',params),
	uploadFilesTags: (params) => 
		submitformNew('security/upload_files/tags',params),
	save_security_tag: params => 
		submitformNewPostForm('security/save_security_tag',params),
	get_source_list_language: params => 
		requestNew('language/get_source_list',params),
	get_translations: params => 
		requestNew('language/get_translations',params),
	language_save: (params) => 
		requestNew('language/save',params),	
	delete_source_language: (params) => 
		requestNew('language/delete_source',params),
	get_source_detail: (params) => 
		requestNew('language/get_source_detail',params),
	export_translations: (params) => 
		requestNew('language/export_translations',params),
	save_translate: (params) => 
		submitformNewPostForm('language/save_translate',params),	
	get_translation_detail: (params) => 
		requestNew('language/get_translation_detail',params),
	load_translate_file: (params) => 
		submitformNewPostForm('language/load_translate_file',params),
	get_master_courses: (params) => 
		requestNew('course/get_master_courses',params),
	save_master_course: (params) => 
		submitformNewPostForm('course/save_master_course',params),
	get_global_dep: (params) => 
		requestGet('department/get_global_dep',params),
	get_master_course: (params) => 
		requestNew('course/get_master_course',params),
	delete_master_course: (params) => 
		requestDELETE('course/delete_master_course',params),
	delete_translation: (params) => 
		requestNew('language/delete_translation',params),
	get_list_global_department: (params) => 
		requestNew('global_department/get_list',params),
	save_global_department: (params) => 
		requestNew('global_department/save',params),
	get_detail_global_department: (params) => 
		requestNew('global_department/get_detail',params),
	delete_global_department: (params) => 
		requestDELETE('global_department/delete',params),
	get_languages_list: (params) => 
		requestNew('language/get_languages',params),
	save_language_new: (params) => 
		requestNew('language/save_language',params),
	upload_flag: (params) => 
		submitformNew('language/upload_files/flags',params),
	get_language_detail: (params) => 
		requestNew('language/get_language_detail',params),
	delete_language: (params) => 
		requestNew('language/delete_language',params),
	get_advisees: (params) => 
		requestNew('teacher/get_advisees',params),
	get_sections_by_teacherid: (params) => 
		requestNew('teacher/get_sections_by_teacherid',params),
	chat_openapi: (params) => 
		requestNew('openai/chat',params),
	delete_rules: (params) => 
		submitformNewPostForm('notifications/delete_rules',params),
	delete_templates: (params) => 
		submitformNewPostForm('notifications/delete_templates',params),
	copy_notification_rules_schoolgroups: params => 
		requestNew('notifications/copy_notification_rules_schoolgroups',params),
	get_role_notify_rule: params => 
		requestNew('notifications/get_role_notify_rule',params),
	resend_notifications: params => 
		requestNew('notifications/resend',params),
	get_object_state_rules: params => 
		requestNew('notifications/get_object_state_rules',params),
	save_object_state_rule: params => 
		requestNew('notifications/save_object_state_rule',params),
	view_object_state_rule: params => 
		requestNew('notifications/view_object_state_rule',params),
	delete_object_state_rule: params => 
		requestNew('notifications/delete_object_state_rule',params),
	get_configuration_groups: params => 
		requestNew('schoolgroup/get_configuration_groups',params),
	get_object_types: params => 
		requestGet('notifications/get_object_types',params),
	get_school_program_type: params => 
		requestGet('common/get_school_program_type',params),
	get_staff_role: params => 
		requestNew('online_content/get_staff_roles',params),
	

}

const openAi={
	get_aicourse_list: params =>
		requestNew('openai/get_aicourse_list', params),
	delete_aicourse: params =>
		requestNew('openai/delete_aicourse', params),
	save_aicourse: params =>
		requestNew('openai/save_aicourse', params),	
	view_aicourse: params =>
		requestNew('openai/view_aicourse', params),	
	get_list_department: params =>
		requestNew('department/get_list', params),
	get_aiunit_list: params =>
		requestNew('openai/get_aiunit_list', params),
	save_aiunit: params =>
		requestNew('openai/save_aiunit', params),
	view_aiunit: params =>
		requestNew('openai/view_aiunit', params),
	delete_aiunit: params =>
		requestNew('openai/delete_aiunit', params),
	get_ailesson_list: params =>
		requestNew('openai/get_ailesson_list', params),
	save_ailesson: params =>
		requestNew('openai/save_ailesson', params),
	view_ailesson: params =>
		requestNew('openai/view_ailesson', params),
	delete_ailesson: params =>
		requestNew('openai/delete_ailesson', params),
	get_ailesson_content_list: params =>
		requestNew('openai/get_ailesson_content_list', params),
	save_ailesson_content: params =>
		requestNew('openai/save_ailesson_content', params),
	view_ailesson_content: params =>
		requestNew('openai/view_ailesson_content', params),
	get_history: params =>
		requestNew('openai/get_history', params),

}
const docRequest={
	get_request_type_list: params =>
		requestNew('doc_request/get_request_type_list', params),
	save_request_type: params =>
		requestNew('doc_request/save_request_type', params),
	view_request_type: params =>
		requestNew('doc_request/view_request_type', params),
	get_request_template_list: params =>
		requestNew('doc_request/get_request_template_list', params),
	get_auth_url: params =>
		requestGet('google/get_auth_url', params),
	google_login: params =>
		requestNew('google/login', params),
	save_request_template: params =>
		requestNew('doc_request/save_request_template', params),
	view_request_template: params =>
		requestNew('doc_request/view_request_template', params),
	delete_request_template: params =>
		requestNew('doc_request/delete_request_template', params),
	delete_request_type: params =>
		requestNew('doc_request/delete_request_type', params),
	get_google_forms: params =>
		requestNew('doc_request/get_google_forms', params),
	get_request_rule_list: params =>
		requestNew('doc_request/get_request_rule_list', params),
	save_request_rule: params =>
		requestNew('doc_request/save_request_rule', params),
	view_request_rule: params =>
		requestNew('doc_request/view_request_rule', params),
	delete_request_rule: params =>
		requestNew('doc_request/delete_request_rule', params),
	get_request_assign_user: params =>
		requestNew('doc_request/get_request_assign_user', params),
	save_response: params =>
		requestNew('doc_request/save_response', params),
	get_request_queue_list: params =>
		requestNew('doc_request/get_request_queue_list', params),
	

}




const setToken = _token => {
	token = _token
}

export default {
  home,
  messge,
  chat,
  parents,
  student,
  teacher,
  admin,
  reports,
  superadmin,
  openAi,
  docRequest,
  auth,
  setToken
};